import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeGuard } from './guards/home.guard';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { UnauthenticatedGuard } from './guards/unauthenticated.guard';
import { DashboardPageGuard } from './guards/dashboard-page.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthenticatedGuard, HomeGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then( m => m.LoginPageModule),
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'new-password',
    loadChildren: () => import('./modules/first-password/first-password.module').then( m => m.FirstPasswordPageModule),
    canActivate: [UnauthenticatedGuard]
  },
  {
    path: 'forgot-password-otp',
    loadChildren: () => import('./modules/forgot-password-otp/forgot-password-otp.module').then( m => m.ForgotPasswordOtpPageModule),
    canActivate: [UnauthenticatedGuard]
  },
  {
    path: 'forgot-password-update',
    loadChildren: () => import('./modules/forgot-password-update/forgot-password-update.module').then( m => m.ForgotPasswordUpdatePageModule),
    canActivate: [UnauthenticatedGuard]
  },
  {
    path: 'boarding',
    loadChildren: () => import('./modules/boarding/boarding.module').then( m => m.BoardingPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthenticatedGuard, DashboardPageGuard]
  },
  {
    path: 'create-order',
    loadChildren: () => import('./modules/create-order/create-order.module').then( m => m.CreateOrderPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('./modules/cart/cart.module').then( m => m.CartPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./modules/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'check-rate',
    loadChildren: () => import('./modules/check-rate/check-rate.module').then( m => m.CheckRatePageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payments',
    loadChildren: () => import('./modules/payment/payment-list/payment-list.module').then( m => m.PaymentListPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payments/change',
    loadChildren: () => import('./modules/payment/payment-change-method/payment-change-method.module').then( m => m.PaymentChangeMethodPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payments/:uuid',
    pathMatch: 'full',
    redirectTo: 'payments/:uuid/countdown'
  },
  {
    path: 'payments/:uuid/countdown',
    loadChildren: () => import('./modules/payment/payment-countdown/payment-countdown.module').then( m => m.PaymentCountdownPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payment-cash',
    loadChildren: () => import('./modules/payment-cash/payment-cash-list/payment-cash-list.module').then( m => m.PaymentCashListPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payment-cash/:uuid',
    loadChildren: () => import('./modules/payment-cash/payment-cash-detail/payment-cash-detail.module').then( m => m.PaymentCashDetailPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payment-cash/:uuid/create',
    loadChildren: () => import('./modules/payment-cash/payment-cash-checkout/payment-cash-checkout.module').then( m => m.PaymentCashCheckoutPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/pesanan/pesanan-list/pesanan-list.module').then( m => m.PesananListPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'orders/:uuid',
    loadChildren: () => import('./modules/pesanan/pesanan-detail/pesanan-detail.module').then( m => m.PesananDetailPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'inbounds',
    loadChildren: () => import('./modules/inbound/inbound-list/inbound-list.module').then( m => m.InboundListPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'inbounds/:awb/validate',
    loadChildren: () => import('./modules/inbound/inbound-validate/inbound-validate.module').then( m => m.InboundValidatePageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'inbounds/cart',
    loadChildren: () => import('./modules/inbound/inbound-cart/inbound-cart.module').then( m => m.InboundCartPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'inbounds/checkout',
    loadChildren: () => import('./modules/inbound/inbound-trouble-checkout/inbound-trouble-checkout.module').then( m => m.InboundTroubleCheckoutPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'manifests',
    loadChildren: () => import('./modules/outbound/outbound-listing/outbound-listing.module').then( m => m.OutboundListingPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'manifests/:uuid',
    loadChildren: () => import('./modules/manifest/manifest-detail/manifest-detail.module').then( m => m.ManifestDetailPageModule)
  },
  {
    path: 'bags/:uuid',
    pathMatch: 'full',
    redirectTo: 'bags/:uuid/edit',
  },
  {
    path: 'bags/:uuid/edit',
    loadChildren: () => import('./modules/bag/bag-edit/bag-edit.module').then( m => m.BagEditPageModule ),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'handovers',
    loadChildren: () => import('./modules/handover/handover-listing/handover-listing.module').then( m => m.HandoverListingPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'handovers/:manifestUuid',
    loadChildren: () => import('./modules/handover/handover-courier/handover-courier.module').then( m => m.HandoverCourierPageModule)
  },
  {
    path: 'handovers/progress/:manifestUuid',
    loadChildren: () => import('./modules/handover/handover-proses/handover-proses.module').then( m => m.HandoverProsesPageModule)
  },
  {
    path: 'contents',
    loadChildren: () => import('./modules/content/content-list/content-list.module').then( m => m.ContentListPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'tracking',
    loadChildren: () => import('./modules/tracking/tracking.module').then( m => m.TrackingPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'balances',
    loadChildren: () => import('./modules/balance/balance-listing/balance-listing.module').then( m => m.BalanceListingPageModule)
  },
  {
    path: 'balances/courier/:uuid',
    loadChildren: () => import('./modules/balance/balance-detail/balance-detail-courier/balance-detail-courier.module').then( m => m.BalanceDetailCourierPageModule)
  },
  {
    path: 'balances/pickup/:uuid',
    loadChildren: () => import('./modules/balance/balance-detail/balance-detail-pickup/balance-detail-pickup.module').then( m => m.BalanceDetailPickupPageModule)
  },
  {
    path: 'balances/invoice/:uuid',
    loadChildren: () => import('./modules/balance/balance-detail/balance-detail-invoice/balance-detail-invoice.module').then( m => m.BalanceDetailInvoicePageModule)
  },
  {
    path: 'balances/claim',
    loadChildren: () => import('./modules/balance/balance-claim/balance-claim.module').then( m => m.BalanceClaimPageModule)
  },
  {
    path: 'balances/claim/:uuid',
    loadChildren: () => import('./modules/balance/balance-claim-detail/balance-claim-detail.module').then( m => m.BalanceClaimDetailPageModule)
  },
  {
    path: 'pending-balance',
    loadChildren: () => import('./modules/balance/balance-pending-listing/balance-pending-listing.module').then( m => m.BalancePendingListingPageModule)
  },
  {
    path: 'settings',
    pathMatch: 'full',
    redirectTo: 'settings/profile'
  },
  {
    path: 'settings/menu',
    loadChildren: () => import('./modules/setting/setting-menu/setting-menu.module').then( m => m.SettingMenuPageModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'settings/profile',
    loadChildren: () => import('./modules/setting/setting-profile/setting-profile.module').then( m => m.SettingProfilePageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'settings/agent',
    loadChildren: () => import('./modules/setting/setting-agen/setting-agen.module').then( m => m.SettingAgenPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'settings/admin',
    loadChildren: () => import('./modules/setting/setting-admin/setting-admin.module').then( m => m.SettingAdminPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'remote-area',
    loadChildren: () => import('./modules/remote-area/remote-area.module').then( m => m.RemoteAreaPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
