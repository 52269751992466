// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // api_url: 'http://192.168.1.6/AbangRestApi/public',
  // api_url_agen: 'http://192.168.1.6/AbangRestApi/public/agen-cabang',
  // api_url_mitra: 'https://192.168.1.6/AbangRestApi/public/mitra/v3',
  // api_url_internal: 'http://192.168.1.6/AXInternal/api/v1',

  // api_url: 'https://testing-api.abangexpress.co.id',
  // api_url_agen: 'https://testing-api.abangexpress.co.id/agen-cabang',
  // api_url_mitra: 'https://testing-api.abangexpress.co.id/mitra/v3',
  // api_url_internal: 'https://testing-internal.abangexpress.co.id/api/v1',

  // api_url: 'http://abangrestapi.ae',
  // api_url_agen: 'http://abangrestapi.ae/agen-cabang',
  // api_url_mitra: 'http://abangrestapi.ae/mitra/v3',
  // api_url_internal: 'http://axinternal.ae/api/v1',
  // api_url_notif_service: 'https://ax-gateway.ap.ngrok.io/api',

  api_url: 'https://devuat.abangexpress.co.id',
  api_url_agen: 'https://devuat.abangexpress.co.id/agen-cabang',
  api_url_mitra: 'https://devuat.abangexpress.co.id/mitra/v3',
  api_url_internal: 'https://internal.abangexpress.co.id/api/v1',
  api_url_notif_service: 'https://gateway.abangexpress.co.id/api',

  api_url_support: 'https://support.abangexpress.co.id/api',

  version: '1.5.10',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
