import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global/global.service';

export const IconType = {
  MENU: 'menu',
  EYE_OFF: 'eye-off',
  EYE_ON: 'eye-on',
  EYE_ON_OUTLINE: 'eye-on-outline',
  CHEVRON_DOWN_FILL: 'chevron-down-fill',
  CHEVRON_LEFT: 'chevron-left',
  SEARCH: 'search',
  TROLI: 'troli',
  BELL: 'bell',
  CLOSE: 'close',
  TICK: 'tick',
  TICK_CIRCLE: 'tick-circle',
  CLOSE_CIRCLE: 'close-circle',
  LIST: 'list',
  CASH_PLUS: 'cash-plus',
  CONTACT_OUTLINE: 'contact-outline',
  ARROW: 'arrow',
  ARROW_LEFT: 'arrow-left',
  SHIELD_TICK: 'shield-tick',
  QUESTION_CIRCLE: 'question-circle',
  EXCLAMATION_CIRCLE: 'exclamation-circle',
  EXCLAMATION_CIRCLE_OUTLINE: 'exclamation-circle-outline',
  CAMERA_PLUS_OUTLINE: 'camera-plus-outline',
  EDIT_OUTLINE: 'edit-outline',
  TRASH_OUTLINE: 'trash-outline',
  CLIPBOARD_OUTLINE: 'clipboard-outline',
  CONTENT_OUTLINE: 'content-outline',
  PRINT_OUTLINE: 'print-outline',
  RELOAD_OUTLINE: 'reload-outline',
  TIME_OUTLINE: 'time-outline',
  DANGER_OUTLINE: 'danger-outline',
  ORDER_SEARCH_OUTLINE: 'order-search-outline',
  SCAN_OUTLINE: 'scan-outline',
  ENTER_OUTLINE: 'enter-outline',
  CLOSE_CIRCLE_OUTLINE: 'close-circle-outline',
  TICK_CIRCLE_OUTLINE: 'tick-circle-outline',
  DOC_DOWNLOAD_OUTLINE: 'doc-download-outline',
  ELLIPSIS_HORIZONTAL_OUTLINE: 'ellipsis-horizontal-outline',
  ADD_SQUARE: 'add-square',
  ADD_CIRCLE: 'add-circle',
  ADD_SQUARE_OUTLINE: 'add-square-outline',
  MINUS_CIRCLE: 'minus-circle',
  DELIVERY_OUTLINE: 'delivery-outline',
  WARNING_HEXA_FILL: 'warning-hexa-fill',
  GALLERY_OUTLINE: 'gallery-outline',
  CALENDAR_EDIT_OUTLINE: 'calendar-edit-outline',
  DELIVERY_BOX_OUTLINE: 'delivery-box-outline',
  HELP_CIRCLE_OUTLINE: 'help-circle-outline',
  CHAT_OUTLINE: 'chat-outline',
  WHATSAPP_OUTLINE: 'whatsapp-outline',
  PUSH_LEFT_OUTLINE: 'push-left-outline',
  MONEY_FORBIDDEN_OUTLINE: 'money-forbidden-outline',
  BAG_OUTLINE: 'bag-outline',
  TICK_STAR_OUTLINE: 'tick-star-outline',
  RECEIPT_SEARCH_OUTLINE: 'receipt-search-outline',
  MAIL_OUTLINE: 'mail-outline',
  CHAT_DOT_OUTLINE: 'chat-dot-outline',
  TASK_SQUARE: 'task-square',
  SMS_TRACKING: 'sms-tracking',
  INFO_CIRCLE_OUTLINE: 'info-circle-outline',
  DOWNLOAD_OUTLINE: 'download-outline',
  FILTER_OUTLINE: 'filter-outline',
  ROTATE_LEFT_OUTLINE: 'rotate-left-outline',

  C_MAP: 'c-map',
  C_CALCULATOR: 'c-calculator',
  C_PREMIUM: 'c-premium',
  C_LARTAS: 'c-lartas',

  NAV_DASHBOARD: 'nav-dashboard',
  NAV_PICKUP: 'nav-pickup',
  NAV_HANDOVER: 'nav-handover',
  NAV_HOME: 'nav-home',
  NAV_INBOUND: 'nav-inbound',
  NAV_MANIFEST: 'nav-manifest',
  NAV_FINANCE: 'nav-finance',
  NAV_HELP: 'nav-help',
  NAV_SETTING: 'nav-setting',
  NAV_DARK_THEME: 'nav-dark-theme',
  NAV_LIGHT_THEME: 'nav-light-theme',
  NAV_MENU: 'nav-menu',
  NAV_CHAT: 'nav-chat',

  HAMBURGER_MENU: 'hamburger-menu',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  APPLE: 'apple',
} as const;

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase]
})
export class IconComponent implements OnInit {
  @Input()
  type: typeof IconType[keyof typeof IconType] = IconType.MENU;

  unique = this.global.getUniqueId(4);

  ICON_TYPE = IconType;

  constructor(private global: GlobalService) { }

  ngOnInit() {
  }
}

