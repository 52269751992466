import { Component, HostListener, NgZone } from '@angular/core';
import { GetStorage, SetStorage, StorageService } from './services/storage/storage.service';
import { AuthService } from './services/auth/auth.service';
import { NAV_ROUTE, NavigationService } from './services/navigation/navigation.service';
import { Subscription, lastValueFrom, skip } from 'rxjs';
import { StateService } from './services/state/state.service';
import { MAX_MOBILE_WIDTH, TOAST_TYPE } from './constants/global.const';
import { GlobalService } from './services/global/global.service';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-ios-app-tracking';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { ScanService } from './services/scan/scan.service';
import { Keyboard } from '@capacitor/keyboard';
import { Channel, PushNotifications } from '@capacitor/push-notifications';
import { RestService } from './services/rest/rest.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  closeClickCount: number = 0;

  isCameraActive: boolean = false;
  isCameraActive$?: Subscription;

  isScanActive: boolean = false;
  isScanActive$?: Subscription;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const isMobileSizeScreen = window.innerWidth <= MAX_MOBILE_WIDTH;
    const isCurrentMobileSizeScreen = this.state.isMobileScreen.value;
    if (isCurrentMobileSizeScreen !== isMobileSizeScreen) {
      this.state.isMobileScreen.next(window.innerWidth < MAX_MOBILE_WIDTH);
    }
  }

  constructor(
    public state: StateService,
    public global: GlobalService,
    private route: Router,
    private auth: AuthService,
    private platform: Platform,
    private storage: StorageService,
    private navigation: NavigationService,
    private alertController: AlertController,
    private router: Router,
    public scan: ScanService,
    private zone: NgZone,
    private rest: RestService
  ) {
    SplashScreen.show({ autoHide: false }).then((_) => {
      this.platform.ready().then((_) => SplashScreen.hide());
    });

    // Handle authentication state
    // If user is not authenticated, clear session storage and redirect to login page
    this.auth.isAuthenticated.pipe(skip(1)).subscribe((isAuthenticated) => {
      if (isAuthenticated) return;
      this.storage.removeSession();
      this.navigation.toLoginPage();
      this.state.resetStateOnLogout();
    });
  }

  ngOnInit(): void {
    // Handle iOS 14+ App Tracking Transparency requirement
    // https://developer.apple.com/documentation/apptrackingtransparency
    if (this.platform.is('ios') && Capacitor.isNativePlatform()) {
      AppTrackingTransparency.requestPermission();
    }

    this.subscribe();
    this.initiateApp();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  initNotification() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        const channel: Channel = {
          id: 'abangexpress',
          name: 'Abang Express Notification',
          description: 'Channel Abang Express Notification',
          sound: 'abangexpress.mp3', // Mengubah suara notifikasi ke suara baru dari res/raw android
          importance: 5, // Atur sesuai kebutuhan
          visibility: 1, // Atur sesuai kebutuhan
        };

        PushNotifications.createChannel({
          id: channel.id,
          name: channel.name,
          description: channel.description,
          sound: channel.sound, // Mengubah suara notifikasi ke suara baru dari res/raw android
          importance: channel.importance, // Atur sesuai kebutuhan
          visibility: channel.visibility, // Atur sesuai kebutuhan
        });
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration', async (token) => {
      await SetStorage('token_notif', token.value);
      let userId = await GetStorage('userID');
      if (userId == null) {
        userId = 'null' + this.global.getUniqueId(1);
      }
      await lastValueFrom(
        this.rest.registerTokenNotification({ token: token.value, users_id: userId })
      );
    });

    PushNotifications.addListener('registrationError', (error) =>
      alert('Error on registration: ' + JSON.stringify(error))
    );

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log(notification.id);
      console.log(notification.data);
      console.log(notification.title);
      console.log(notification.body);
    });
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {});
  }

  async initiateApp() {
    await this.populateRequiredStorage();
    this.setInitialTheme();
    this.listenKeyboardDevice();
    this.populateRequiredState();

    if (this.platform.is('android')) {
      this.initNotification();
    }

    // If the app is running on native devices, setup native app features
    // such as back button, app update, and deeplink
    if (this.platform.is('hybrid')) {
      this.checkHasUpdate();
      this.registerBackButton();
      this.setupDeeplink();
      this.registerKeybooardEvent();

      // If no is_passed_boarding stored in storage,
      // means its the first time user open the app
      // redirect to boarding page
      if (!this.storage.is_passed_boarding) this.navigation.toBoardingPage();

      // To tackle the issue of navigation stack
      // on native devices
      this.router.navigate(['/']);
    }
  }

  async populateRequiredStorage() {
    await this.storage.loadInitialStorages();
    if (this.storage.profile) this.auth.isAuthenticated.next(true);
  }

  async populateRequiredState() {
    this.state.populateExtraRules();

    if (!this.storage.token) return;
    this.state.populateProfile();
    this.state.populateCartItems();
    this.state.populateTroubleInbounds();
  }

  async setInitialTheme() {
    let theme: 'light' | 'dark' = 'light';

    // Use prefers-color-scheme as a default value
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      theme = 'dark';
    }

    // Override with user preference from storage
    if (this.storage.theme) {
      theme = this.storage.theme;
    }

    this.storage.setTheme(theme);
    this.global.toggleDarkTheme(theme);
    this.global.changeStatusBar(theme);
  }

  registerKeybooardEvent() {
    if (!Capacitor.isPluginAvailable('Keyboard')) return;
    Keyboard.addListener('keyboardWillShow', () => {
      document.body.classList.add('keyboard-open');
    });
    Keyboard.addListener('keyboardWillHide', () => {
      document.body.classList.remove('keyboard-open');
    });
  }

  async checkHasUpdate() {
    if (!this.platform.is('android') && !this.platform.is('ios')) return;

    const updateInfo = await AppUpdate.getAppUpdateInfo();
    this.global.appConfig.versionApp =
      updateInfo.availableVersionCode || this.global.appConfig.versionApp;
    const hasUpdate = updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
    if (!hasUpdate) return;

    this.presentUpdateAlert();
  }

  async presentUpdateAlert() {
    const alert = await this.alertController.create({
      header: 'New Version Available',
      message: 'Please update AbangExpress app to the latest one and enjoy better experience',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            App.exitApp();
          },
        },
        {
          text: 'Update',
          handler: () => {
            this.openNativeStore()
              .then(() => App.exitApp())
              .catch((error) => this.global.errorHandler(error, 'Gagal membuka play/app store'));
          },
        },
      ],
      backdropDismiss: false,
    });

    alert.present();
  }

  async immediateUpdate() {
    try {
      await AppUpdate.performImmediateUpdate();
    } catch (error) {
      this.global.errorHandler(error, `Failed on direct update`);
    }
  }

  async openNativeStore() {
    this.alertController.dismiss();

    try {
      await AppUpdate.openAppStore();
    } catch (error) {
      this.global.errorHandler(
        error,
        `Failed on opening ${this.platform.is('ios') ? 'App' : 'Play'}Store`
      );
    }
  }

  setupDeeplink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('mitra.abangexpress.co.id').pop();
        if (slug) this.global.showToast(`DEEPLINK ${slug}`, TOAST_TYPE.INFO);
      });
    });
  }

  registerBackButton() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      const closeableRoutes = [NAV_ROUTE.LOGIN, NAV_ROUTE.HOME_DASHBOARD];
      if (closeableRoutes.includes(this.route.url)) {
        this.closeClickCount++;
        if (this.closeClickCount >= 2) {
          this.closeClickCount = 0;
          App.exitApp();
          return;
        }

        // App will close if user click back button / swipe back
        // within 3 second interval time;
        const ACTION_INTERAL = 3000;
        this.global.showToast('"Back" 2 kali untuk keluar dari aplikasi', TOAST_TYPE.INFO);
        setTimeout(() => (this.closeClickCount = 0), ACTION_INTERAL);
        return;
      }

      this.navigation.pop();
    });
  }

  listenKeyboardDevice() {
    if (!Capacitor.isPluginAvailable('Keyboard')) return;
    Keyboard.addListener('keyboardWillShow', () => {
      this.state.isKeyboardActive.next(true);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      this.state.isKeyboardActive.next(false);
    });
  }

  private subscribe() {
    this.isCameraActive$ = this.state.isCameraActive.subscribe((val) => {
      this.isCameraActive = val;
    });

    this.isScanActive$ = this.state.isScanActive.subscribe((val) => {
      this.isScanActive = val;
      this.zone.run(() => {});
    });
  }

  private unsubscribe() {
    this.isCameraActive$?.unsubscribe();
    this.isScanActive$?.unsubscribe();
  }
}
