import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { StateService } from '../state/state.service';
import { GlobalService } from '../global/global.service';
import { Barcode, BarcodeScannedEvent, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

@Injectable({
  providedIn: 'root'
})
export class ScanService {
  
  result: string = '';
  isScannerActive: boolean = false;

  constructor(
    private state: StateService,
    private global: GlobalService,
    private alertCtrl: AlertController,
  ) { }

  
  async startScan(cb: (ctx: BarcodeScannedEvent) => void ) {
    this.global.setBodyTransparent();
    this.state.isScanActive.next(true);
  
    const listener = await BarcodeScanner.addListener('barcodeScanned', async result => {
      await listener.remove();
      await BarcodeScanner.stopScan();
      cb(result);
    });
  
    await BarcodeScanner.startScan();
  };
  
  async stopScan() {
    this.global.resetBodyTransparent();
    this.state.isScanActive.next(false);
  

    await BarcodeScanner.removeAllListeners();
    await BarcodeScanner.stopScan();
  };
}
