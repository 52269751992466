import { Component, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-preview-image',
  templateUrl: './modal-preview-image.component.html',
  styleUrls: ['./modal-preview-image.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class ModalPreviewImageComponent  implements OnInit {

  @Input()
  src: string = '';

  modal?: HTMLIonModalElement;

  @Output()
  dismiss: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

}
