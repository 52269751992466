import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { BackgroundColorOptions, StatusBar, StyleOptions, Style } from '@capacitor/status-bar';
import { Platform, ToastController, ModalController, AlertController } from '@ionic/angular';
import { TOAST_TYPE } from 'src/app/constants/global.const';
import { AuthService } from '../auth/auth.service';
import { AppConfig, HttpThrownResponse } from 'src/app/types/rest.types';
import { ModalPreviewImageComponent } from 'src/app/components/modal/modal-preview-image/modal-preview-image.component';
import { TemplatePreloadingComponent } from 'src/app/components/template/template-preloading/template-preloading.component';
import { TemplateComingSoonComponent } from 'src/app/components/template/template-coming-soon/template-coming-soon.component';
import { ModalConfirmLogoutComponent } from 'src/app/components/modal/modal-confirm-logout/modal-confirm-logout.component';
import { StorageService } from '../storage/storage.service';
import { EmptyError } from 'rxjs';
import { TemplateLockComponent } from 'src/app/components/template/template-lock/template-lock.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  appConfig: AppConfig = {
    contactAX: '628111361777',
    versionApp: environment.version,
  }

  status = {
    mode: 'Beta',
    version: 'v.1',
  }


  isTransparent: boolean = false;

  loadingAX?: HTMLIonModalElement;
  preloadingAx?: HTMLIonModalElement;

  today: Date = new Date();

  debugCounter: number = 0;

  todoVisible: boolean = false;

  lockDialog?: HTMLIonModalElement;

  clg = console.log;


  constructor(
    private auth: AuthService,
    private platform : Platform,
    private toastCtrl : ToastController,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private alertCtrl: AlertController,
  ) {}

  async changeStatusBar(theme: 'dark' | 'light'){
    if(!Capacitor.isPluginAvailable('StatusBar')) return;

    const optsStatusBar: BackgroundColorOptions = {
      color: theme === 'light' ? '#e5ebf7' : '#1b1b1b',
    };
    const optStatusInfoTheme: StyleOptions = {
      style: theme === 'light' ? Style.Light : Style.Dark,
    };

    await StatusBar.setBackgroundColor(optsStatusBar);
    await StatusBar.setStyle(optStatusInfoTheme)
  }


  async showLoading() {
    this.loadingAX = await this.modalCtrl.create({
      // component: TemplateLoadingComponent,
      component: TemplatePreloadingComponent,
      cssClass: 'mode-popup mode-popup--loading',
      mode: 'ios'
    })
    this.loadingAX.present();

    return this.loadingAX;
  }

  hideLoading() {
    this.loadingAX?.dismiss();
  }

  async showPreloading() {
    this.preloadingAx = await this.modalCtrl.create({
      component: TemplatePreloadingComponent,
      cssClass: 'mode-popup mode-popup--preloading',
      mode: 'ios'
    })
    this.preloadingAx.present();

    return this.preloadingAx;
  }

  hidePreloading() {
    this.preloadingAx?.dismiss();
  }


  async showToast(
    msg: string = '',
    type: 'alpha-surface' | 'good' | 'bad' = 'alpha-surface',
    option: {
      header?: string
      duration?: number
    } = {
      header: '',
      duration: 1500
    }
  ) {
    const toast = await this.toastCtrl.create({
      message : msg,
      color: type,
      header: option.header,
      duration: option.duration ?? 2000,
      cssClass: `toast-${type}`,
      layout: 'stacked',
      position: 'bottom',
      mode: 'ios'
    });
    toast.present();
  }


  async copyToClipboard(item: string): Promise<void> {
    if (!this.platform.is("android") && !this.platform.is("ios") && navigator.clipboard) {
      await navigator.clipboard?.writeText(item);
      this.showToast('Copied to clipboard', TOAST_TYPE.INFO);
      return;
    }

    await Clipboard.write({ string: item });
    this.showToast('Copied to clipboard', TOAST_TYPE.INFO);
  }


  setBodyTransparent() {
    this.isTransparent = true;
    document.getElementsByTagName('body')[0].style.background = 'transparent';
  }

  resetBodyTransparent() {
    this.isTransparent = false;
    document.getElementsByTagName('body')[0].style.background = 'unset';
  }


  errorHandler(error: any, mainMessage: string) {
    // Skip if error is EmptyError
    if (error instanceof EmptyError) return;


    // Handle status 402: status code for private feature / coming soon feature
    if (error.status === 402) {
      this.todo();
      return;
    };

    // Handle validation error from server
    // Usually used for handle request with payload that didn't use form
    if ((error as HttpThrownResponse).error?.errors) {
      const firstKey = Object.keys((error as HttpThrownResponse).error.errors)[0];
      this.showToast((error as HttpThrownResponse).error.errors[firstKey], 'bad', { duration: 2000 });
      return;
    }

    if (error.status === 0) {
      this.showToast('NETWORK ERR: Timeout or No Connection Found', 'bad', { duration: 2000 });
      return;
    }
    if (error.status === HttpStatusCode.Unauthorized) {
      this.showToast('SESSION ERR: Sesi habis, kamu perlu login kembali', TOAST_TYPE.INFO, { duration: 2000 });
      this.auth.isAuthenticated.next(false);
      return;
    }
    if (error.status === HttpStatusCode.NotFound && error.message.toLowerCase().includes('404 not found')) {
      this.showToast(`ROUTE ERR: ${error.error.message || mainMessage}`, 'bad', { duration: 2000 });
      return;
    }
    if (error.status === HttpStatusCode.InternalServerError) {
      this.showToast(`SERVER ERR: ${error.error.message || mainMessage}`, 'bad', { duration: 2000 });
      return;
    }
    if (error.status === HttpStatusCode.UnprocessableEntity) {
      this.showToast(`${error.error.message || mainMessage}`, 'bad', { duration: 2000 });
      return;
    }
    if (error.error?.message) {
      if (error.error.message.includes('[LOCK]')) {
        this.showLockDialog();
        return;
      }
      this.showToast(error.error.message, 'bad', { duration: 2000 });
      return;
    }
    if (error.message) {
      this.showToast(error.message, 'bad', { duration: 2000 });
      return;
    }

    this.showToast(`ERR: ${mainMessage}`, 'bad', { duration: 2000 });
  }


  unsortedKeyval() {
    return 0
  }

  b64toBlob(dataURI: string) {
    let byteString = atob(dataURI);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return ab
  }

  blobToB64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }


  async getPosition() {
    try {
      // const permission = await Geolocation.checkPermissions();
      // if (permission.location !== 'granted') {
      //   await Geolocation.requestPermissions()
      // }

      // const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
      // this.state.position = {
      //   lat: position.coords.latitude,
      //   lng: position.coords.longitude,
      // }
    } catch(error) {
      this.errorHandler(error, 'Gagal memuat lokasi')
    }
  }

  getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  toggleDarkTheme(theme: 'dark' | 'light' = 'dark') {
    if (theme === 'dark') {
      document.body.classList.add('dark');
      return;
    }
    document.body.classList.remove('dark')
  }

  appIsMobile() {
    return this.platform.is('hybrid') || this.platform.is('mobile') || this.platform.is('mobileweb');
  }

  appIsNative() {
    return this.platform.is('hybrid');
  }

  handleContactSupport() {
    window.open(`https://wa.me/${this.appConfig.contactAX}?text&type=phone_number&app_absent=0`, '_blank');
  }

  async previewImage(url: string) {
    const modal = await this.modalCtrl.create({
      component: ModalPreviewImageComponent,
      cssClass: 'app-modal-preview-image',
      mode: 'ios',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      componentProps: { src: url }
    });

    modal.present();
  }

  async logout() {
    const modal = await this.modalCtrl.create({
      component: ModalConfirmLogoutComponent,
      cssClass: 'mode-popup mode-popup--confirm',
      mode: 'ios',
    });

    modal.present();
  }

  async todo() {
    if (this.todoVisible) return;

    const modal = await this.modalCtrl.create({
      component: TemplateComingSoonComponent,
      cssClass: 'mode-popup',
      mode: 'ios',
    });

    modal.present().then(() => {
      this.todoVisible = true;
    });

    modal.onDidDismiss().then(() => {
      this.todoVisible = false;
    });
  }

  downloadFile(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'true';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  isOverflown(element: HTMLElement) {
    // Mostly used for checking if element is overflowed
    // Handled case where ion infinite scroll since the content is not overflowed
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  async showLockDialog() {
    const modal = await this.modalCtrl.create({
      component: TemplateLockComponent,
      cssClass: 'mode-popup',
      mode: 'ios',
    });

    this.lockDialog = modal;
    this.lockDialog.present();
  }

  hideLockDialog() {
    this.lockDialog?.dismiss();
    this.lockDialog = undefined;
  }
}
