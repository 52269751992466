import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NAV_ROUTE } from '../services/navigation/navigation.service';
import { GlobalService } from '../services/global/global.service';
import { StateService } from '../services/state/state.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard {
  constructor(
    private state: StateService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.state.isMobileScreen.value) {
      return this.router.parseUrl(NAV_ROUTE.DASHBOARD)
    }
    return true;
  }
  
}
