import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-template-coming-soon',
  templateUrl: './template-coming-soon.component.html',
  styleUrls: ['./template-coming-soon.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class TemplateComingSoonComponent  implements OnInit {

  modal?: HTMLIonModalElement;

  constructor() { }

  ngOnInit() {}

}
