<ion-app class="{{ isCameraActive || isScanActive ? 'hidden' : '' }}">
  <ion-router-outlet></ion-router-outlet>
</ion-app>

<ng-container *ngIf="isScanActive">
  <div
    class="app-scan">
    <div class="app-scan__header">
      <p>Scan</p>
      <button (click)="scan.stopScan()">
        <app-icon type="close"></app-icon>
      </button>
    </div>
  </div>
</ng-container>
