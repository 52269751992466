import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs';
import { environment } from 'src/environments/environment';

import { StorageService } from '../storage/storage.service';

import { ChannelOtp, OrderReceiptSize } from 'src/app/constants/global.const';
import { ObjectValues } from 'src/app/types/global.types';
import { CountryRequirementResponse, HttpResponse, GetOption, ShippingPrice, LoginForm, OrderValidatePenerimaForm, OrderValidatePengirimForm, PrevPenerimaResponse, PrevPengirimResponse, Commodity, CommodityUnit, GetAvailablePayment, CreateCashInvoice, GetDetailInvoice, GetInvoice, GetOrder, GetDestination, GetInvoiceReview, GetInboundCheckStatus, GetInboundReview, GetTroubleUser, Pagination, GetRemoteArea, GetTracking, GetInboundTroubleInvoice, GetInboundTroubleInvoiceStatus, GetOutboundDestination, GetOutboundBag, GetAgent, GetExportRate, GetExportCriteria, GetImportRate, GetCartItem, CheckOutboundOrder, AppConfig, PrintProformaReqForm, LoginResponse, ChangePasswordRequest, GetProfile, Profile, GetIndonesiaProvinceResp, GetIndonesiaCityResp, GetIndonesiaDistrictResp, GetIndonesiaSubdistrictResp, ChangePaymentResponse, GetProfileStatsResponse, GetCashInvoicesResponse, GetCashInvoiceAvailablePaymentsResponse, GetCashInvoiceResponse, ChangeCashInvoicePaymentMethodResponse, GetSaldoResponse, GetRegistrationProfileResponse, ClaimSaldoPayload, ClaimSaldoResponse, GetSaldoTransactionsResponse, GetBannersResponse, GetExtraRuleResponse, GetPendingRefundsResponse, GetPendingCashbackResponse, HttpResponsePagination, GetSaldoMutationPickupResponse, GetSaldoMutationCourierResponse, GetOrderOutbound } from 'src/app/types/rest.types';
import { Country } from 'src/app/types/model.types';
import { BookingOrderDetailResource, BookingOrderListForInboundResource, OutboundManifestDetailResource, OutboundManifestDetailWithKoliStatusResource } from 'src/app/types/resource.types';
import { GetManifestStatus, GetSaldoMutationSaldoTransactionType, GetSaldoMutationSaldoType } from 'src/app/constants/rest.const';

const TIMEOUT_MS = 50000;
const CREATE_INVOICE_TIMEOUT_MS = 70000; // OVO case

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  login(form: Record<(typeof LoginForm)[keyof typeof LoginForm], string | null>) {
    return this.http
      .post<HttpResponse<LoginResponse>>(`${environment.api_url_agen}/login`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  registerTokenNotification(form :{token:string, users_id:string}) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_notif_service}/notification/create/token`, form, this.getBypassHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  forgotPassword(form: { username: string, ext: string}) {
    return this.http
      .post<HttpResponse<{ username: string }>>(`${environment.api_url_agen}/forgot-password`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  forgotPasswordResend(form: { username: string, ext: string, channel: typeof ChannelOtp[keyof typeof ChannelOtp]  }) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/forgot-password`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  forgotPasswordVerify(form: { username: string, ext: string, otp: string }) {
    return this.http
      .post<HttpResponse<{ token_reset_password: string }>>(`${environment.api_url_agen}/forgot-password/verify`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  forgotPasswordUpdate(form: { username: string, password: string, confirm: string, token: string, ext: string }) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/forgot-password/reset`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }


  getRatesCity(form: { country_id: string, search: string }) {
    return this.http
    .post<HttpResponse<GetOption[]>>(`${environment.api_url}/rates/city`, JSON.stringify(form), this.getHeader())
    .pipe(timeout(TIMEOUT_MS))
  }

  getRatesPostal(form: { country_id: string, city: string, search: string }) {
    return this.http
    .post<HttpResponse<GetOption[]>>(`${environment.api_url}/rates/postal-code`, JSON.stringify(form), this.getHeader())
    .pipe(timeout(TIMEOUT_MS))
  }

  getPrevPengirim() {
    const queryParams = new URLSearchParams({ page: '1', per_page: '10' });
    return this.http
      .get<HttpResponse<PrevPengirimResponse[]>>(`${environment.api_url_agen}/pemesanan/pengirim-sebelumnya?${queryParams.toString()}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getPrevPenerima(search?: string) {
    const queryParams = new URLSearchParams({});
    if (search) queryParams.append('search', search);

    return this.http
      .get<HttpResponse<PrevPenerimaResponse[]>>(`${environment.api_url_agen}/pemesanan/penerima-sebelumnya?${queryParams.toString()}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getCountryRequirement(form: { country_id: string, tipe_layanan?: string }) {
    return this.http
      .post<HttpResponse<CountryRequirementResponse[]>>(`${environment.api_url_agen}/pemesanan/country-requirement`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getCommodities(countryId: string) {
    return this.http
      .get<HttpResponse<Commodity[]>>(`${environment.api_url_agen}/commodity/${countryId}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  validateOrderPengirim(form: Record<(typeof OrderValidatePengirimForm)[keyof typeof OrderValidatePengirimForm], string | null>) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/pemesanan/validate`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  validateOrderPenerima(form: Record<(typeof OrderValidatePenerimaForm)[keyof typeof OrderValidatePenerimaForm], string | null>) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/pemesanan/validate`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  validateOrderPaket(form: any) {
    return this.http
      .post<HttpResponse<{ harga: ShippingPrice[] }>>(`${environment.api_url_agen}/pemesanan/validate`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  validateOrderAll(form: any) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/pemesanan/validate`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  createOrder(form: any) {
    return this.http
      .post<HttpResponse<BookingOrderDetailResource>>(`${environment.api_url_agen}/pemesanan/store`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getCartItems() {
    return this.http
      .get<HttpResponse<GetCartItem[]>>(`${environment.api_url_agen}/pemesanan/keranjang`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  removeCartItems(transaksiUuid: string[]) {
    return this.http
      .delete<HttpResponse<any>>(`${environment.api_url_agen}/pemesanan/keranjang`, { headers: this.getHeader().headers, body: { transaksi_uuid: transaksiUuid } })
      .pipe(timeout(TIMEOUT_MS))
  }

  reviewPayment(transaksiUuid: string[]) {
    return this.http
      .post<HttpResponse<GetInvoiceReview>>(`${environment.api_url_agen}/pembayaran/review`, JSON.stringify({ transaksi_uuid: transaksiUuid }), this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  createInvoice(body: {
    transaksi_uuid: string[]
    available_payment_uuid: string
    phone_number?: string
  }) {
    return this.http
      .post<HttpResponse<CreateCashInvoice>>(`${environment.api_url_agen}/pembayaran/create`, JSON.stringify(body), this.getHeader())
      .pipe(timeout(CREATE_INVOICE_TIMEOUT_MS));
  }

  changeInvoice(invoiceUuid: string, form: {
    available_payment_uuid: string
    phone_number?: string
  }) {
    return this.http
    .patch<HttpResponse<ChangePaymentResponse>>(`${environment.api_url_agen}/pembayaran/change-method/${invoiceUuid}`, JSON.stringify(form), this.getHeader())
    .pipe(timeout(CREATE_INVOICE_TIMEOUT_MS));
  }

  getAvailablePayments() {
    return this.http
      .get<HttpResponse<GetAvailablePayment>>(`${environment.api_url_agen}/pembayaran/available-payment`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  createCashInvoice(transaksiUuid: string[]) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/pembayaran/accept-cash`, JSON.stringify({ transaksi_uuid: transaksiUuid }), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getInvoiceDetail(invoiceUuid: string) {
    return this.http
      .get<HttpResponse<GetDetailInvoice>>(`${environment.api_url_agen}/pembayaran/${invoiceUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  getHowToPay(url: string) {
    return this.http
      .get<any>(url)
      .pipe(timeout(TIMEOUT_MS));
  }

  printOrderReceipt(orderUuid: string, type: ObjectValues<typeof OrderReceiptSize>, isMasked: boolean = true) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/pemesanan/print/${orderUuid}/${type}/${isMasked}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printOrderCustomerReceipt(orderUuid: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/pemesanan/print/${orderUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printOrderPackingList(awbNumber: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/pemesanan/ipl/${awbNumber}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getInvoiceStatus(invoiceUuid: string) {
    return this.http
      .get<HttpResponse<GetDetailInvoice>>(`${environment.api_url_agen}/pembayaran/${invoiceUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  printInvoiceReceipt(invoiceUuid: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/pembayaran/print/${invoiceUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printInvoiceProformaReceipt(form: PrintProformaReqForm) {
    return this.http
      .post<HttpResponse<string>>(`${environment.api_url_agen}/pembayaran/proforma`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  cancelInvoice(nomor_invoice: string) {
    const body = {
      nomor_invoice,
      description: 'Batalin pokoknya mah, ini dari agen cabang (hardcode)',
    }
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/pembayaran/cancel`, JSON.stringify(body), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOrders(status: string, page: number, query: {
    search?: string
    type?: string
    country_id?: number
    tipe_pengiriman?: (1 | 3 | never)[]
    tipe_resi?: ('SINGLE' | 'MULTI' | never)[]
    start_weight?: number
    end_weight?: number
    price_start?: number
    price_end?: number
    start_date?: string
    end_date?: string
  }) {
    const queryParams = new URLSearchParams({ status, page: page.toString() });
    if (query.search) queryParams.append('search', query.search);
    if (query.type) queryParams.append('type', query.type);
    if (query.country_id) queryParams.append('country_id', query.country_id.toString());
    if (query.start_weight) queryParams.append('start_weight', query.start_weight.toString());
    if (query.end_weight) queryParams.append('end_weight', query.end_weight.toString());
    if (query.price_start) queryParams.append('price_start', query.price_start.toString());
    if (query.price_end) queryParams.append('price_end', query.price_end.toString());
    if (query.start_date) queryParams.append('start_date', query.start_date);
    if (query.end_date) queryParams.append('end_date', query.end_date);

    let queryString = queryParams.toString();
    if (query.tipe_pengiriman?.length) queryString += '&' + this.generateQueryParamsArray('tipe_pengiriman', query.tipe_pengiriman);
    if (query.tipe_resi?.length) queryString += '&' + this.generateQueryParamsArray('tipe_resi', query.tipe_resi);

    return this.http
      .get<GetOrder>(`${environment.api_url_agen}/pemesanan/v2?${queryString}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOrder(orderUuid: string) {
    return this.http
      .get<HttpResponse<BookingOrderDetailResource>>(`${environment.api_url_agen}/pemesanan/${orderUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOrderOutbound(query: { page: number, search?: string }) {
    const queryParams = new URLSearchParams({ page: query.page.toString() });
    if (query.search) queryParams.append('search', query.search);

    return this.http
      .get<GetOrderOutbound>(`${environment.api_url_agen}/outbound/transaksi?${queryParams.toString()}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  cancelOrder(orderUuid: string[]) {
    return this.http
      .delete<HttpResponse<any>>(`${environment.api_url_agen}/pemesanan/cancel`, {
        headers: this.getHeader().headers,
        body: JSON.stringify({ transaksi_uuid: orderUuid })
      })
  }

  getProfile() {
    return this.http
      .get<HttpResponse<Profile>>(`${environment.api_url_agen}/user`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getProfileStats() {
    return this.http
      .get<HttpResponse<GetProfileStatsResponse>>(`${environment.api_url_agen}/stats/overview`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  updateProfilePic(base64picture: string) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_mitra}/profile`, JSON.stringify({ picture: base64picture }), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  changePassword(form: ChangePasswordRequest, token?: string) {
    let header = this.getHeader();
    if (token) header = this.getHeaderStaticToken(token);

    return this.http
      .post<HttpResponse<Profile>>(`${environment.api_url_agen}/change-password`, JSON.stringify(form), header)
      .pipe(timeout(TIMEOUT_MS))
  }

  getInboundStatus(awbNumber: string) {
    return this.http
      .get<HttpResponse<{ data: GetInboundCheckStatus, type: 'MANIFEST' | 'RETAIL' }, string>>(`${environment.api_url_agen}/inbound/check/${awbNumber}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  validateInbound(form: { awb_number: string, koli: any[]}) {
    return this.http
      .post<HttpResponse<GetInboundReview>>(`${environment.api_url_agen}/inbound/review`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  submitInbound(form: { awb_number: string, koli?: any[]}) {
    return this.http
      .post<HttpResponse<{ code: 0 | 1, data: BookingOrderDetailResource | BookingOrderListForInboundResource }>>(`${environment.api_url_agen}/inbound/submit`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  submitInboundFromAgent(form: { awb_number: string, koli?: any[]}) {
    return this.http
      .post<{ code: 0 | 1, data: BookingOrderDetailResource | BookingOrderListForInboundResource }>(`${environment.api_url_agen}/inbound/submit`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  createTroubleInboundsInvoice(form: any) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/inbound/trouble`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getTroubleInboundInvoices(status: GetInboundTroubleInvoiceStatus) {
    return this.http
      .get<HttpResponse<GetInboundTroubleInvoice[]>>(`${environment.api_url_agen}/inbound/invoice?status=${status}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOutboundBags() {
    return this.http
      .get<HttpResponse<GetOutboundBag[]>>(`${environment.api_url_agen}/outbound/bag`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOutboundBag(bagUuid: string, masked: boolean = false) {
    return this.http
      .get<HttpResponse<GetOutboundBag>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}${masked ? '/1' : ''}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  createOutboundBag() {
    return this.http
      .post<HttpResponse<GetOutboundBag>>(`${environment.api_url_agen}/outbound/bag`, JSON.stringify({}), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  updateOutboundBag(bagUuid: string, form: { weight_input: number | null, document: string }) {
    return this.http
      .patch<HttpResponse<GetOutboundBag>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  // checkOutboundAddBagResi(bagUuid: string, awbNumber: string) {
  //   return this.http
  //     .get<HttpResponse<CheckOutboundOrder>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/${awbNumber}/add`, this.getHeader())
  //     .pipe(timeout(TIMEOUT_MS))
  // }
  checkOutboundAddBagResi(awbNumber: string) {
    return this.http
      .get<HttpResponse<CheckOutboundOrder>>(`${environment.api_url_agen}/outbound/bag/${awbNumber}/add`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  // addOutboundBagResi(bagUuid: string, form: { awb_number: string, no_resi: string[] }) {
  //   return this.http
  //   .post<HttpResponse<GetOutboundBag>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}`, JSON.stringify(form), this.getHeader())
  //   .pipe(timeout(TIMEOUT_MS))
  // }
  addOutboundBagResi(bagUuid: string, awbNumber: string) {
    return this.http
    .post<HttpResponse<GetOutboundBag>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/${awbNumber}/add`, JSON.stringify({}), this.getHeader())
    .pipe(timeout(TIMEOUT_MS))
  }

  // checkOutboundRemoveBagResi(bagUuid: string, awbNumber: string) {
  //   return this.http
  //     .get<HttpResponse<CheckOutboundOrder>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/${awbNumber}/remove`, this.getHeader())
  //     .pipe(timeout(TIMEOUT_MS))
  // }
  checkOutboundRemoveBagResi(bagUuid: string, awbNumber: string) {
    return this.http
      .get<HttpResponse<CheckOutboundOrder>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/${awbNumber}/remove`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  // removeOutboundBagResi(bagUuid: string, form: { awb_number: string, no_resi: string[] }) {
  //   const headers = {
  //     ...this.getHeader(),
  //     body: form,
  //   }
  //   return this.http
  //     .delete<HttpResponse<any>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/${form.awb_number}`, headers)
  //     .pipe(timeout(TIMEOUT_MS))
  // }
  removeOutboundBagResi(bagUuid: string, awbNumber: string ) {
    return this.http
      .delete<HttpResponse<any>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/${awbNumber}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  removeOutboundBag(bagUuid: string) {
    return this.http
    .delete<HttpResponse<any>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}`, this.getHeader())
    .pipe(timeout(TIMEOUT_MS))
  }

  printOutboundBag(bagUuid: string) {
    return this.http
      .get<HttpResponse<any>>(`${environment.api_url_agen}/outbound/bag/${bagUuid}/print`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  getOutboundManifests(status: typeof GetManifestStatus[keyof typeof GetManifestStatus], page: number = 1) {
    return this.http
      .get<HttpResponsePagination<OutboundManifestDetailResource>>(`${environment.api_url_agen}/outbound/manifest?status=${status}&page=${page}`, this.getHeader())
      // .get<OutboundManifestDetailResource[]>(`${environment.api_url_agen}/outbound/manifest?status=${status}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOutboundManifest(manifestUuid: string) {
    return this.http
      .get<HttpResponse<OutboundManifestDetailResource>>(`${environment.api_url_agen}/outbound/manifest/${manifestUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOutboundManifestKoli(manifestUuid: string) {
    return this.http
      .get<HttpResponse<OutboundManifestDetailWithKoliStatusResource>>(`${environment.api_url_agen}/outbound/manifest/${manifestUuid}/koli`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOpenOutboundManifest() {
    return this.http
      .get<OutboundManifestDetailResource>(`${environment.api_url_agen}/outbound/manifest/open`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  createOutboundManifest(form: any) {
    return this.http
      .post<HttpResponse<OutboundManifestDetailResource>>(`${environment.api_url_agen}/outbound/manifest`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  deleteOutboundManifest(manifestUuid: string) {
    return this.http
      .delete<HttpResponse<OutboundManifestDetailResource>>(`${environment.api_url_agen}/outbound/manifest/${manifestUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printOutboundManifest(manifestNumber: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/outbound/manifest/print/${manifestNumber}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printOutboundManifestKalog(manifestUuid: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/outbound/manifest/${manifestUuid}/print`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  createOutboundHandover(manifestUuid: string, form: any) {
    return this.http
      .patch<HttpResponse<any>>(`${environment.api_url_agen}/outbound/manifest/${manifestUuid}`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  createOutboundHandoverKalog(manifestUuid: string, form: any) {
    return this.http
      .patch<HttpResponse<any>>(`${environment.api_url_agen}/outbound/manifest/${manifestUuid}`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getRemoteArea(form: any, page: number) {
    return this.http
      .post<HttpResponse<Pagination<GetRemoteArea[]>>>(`${environment.api_url_agen}/remote-area?page=${page}`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getTracking(awb: string) {
    return this.http
      .get<HttpResponse<Record<string, GetTracking | Record<string, GetTracking>>>>(`${environment.api_url}/tracking?awb=${awb}`, this.getBaseHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getExportRate(form: any) {
    return this.http
      .post<HttpResponse<{ criteria: GetExportCriteria, data: GetExportRate }>>(`${environment.api_url_agen}/rates/check`, JSON.stringify(form), this.getBaseHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getImportRate(params: any) {
    return this.http
      .get<GetImportRate>(`${environment.api_url_support}/rate`, { params })
      .pipe(timeout(TIMEOUT_MS))
  }

  getAppConfig() {
    return this.http
      .get<AppConfig>(`https://banjar.trinix.id/v1/kv/dotenv/abangexpress/config/Config.json?raw=true`, this.getHeaderForConfig())
      .pipe(timeout(TIMEOUT_MS))
  }

  getInvoices(status: string, query: { page: number, per_page: number }) {
    return this.http
      .post<HttpResponsePagination<GetInvoice>>(`${environment.api_url_agen}/pembayaran/list`, JSON.stringify({ status, page: query.page, per_page: query.per_page }), this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  getCashInvoiceOrders() {
    return this.http
      .get<HttpResponse<GetOrder[]>>(`${environment.api_url_agen}/pembayaran/cash/order`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  getCashInvoices(status: string) {
    return this.http
      .get<GetCashInvoicesResponse>(`${environment.api_url_agen}/pembayaran/tunai?status=${status}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  getCashInvoice(invoiceUuid: string) {
    return this.http
      .get<GetCashInvoiceResponse>(`${environment.api_url_agen}/pembayaran/tunai/${invoiceUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  getCashInvoiceAvailablePayments(invoiceUuid: string) {
    return this.http
      .get<HttpResponse<GetCashInvoiceAvailablePaymentsResponse>>(`${environment.api_url_agen}/pembayaran/tunai/available-payment?invoice_uuid=${invoiceUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  changeCashInvoicePaymentMethod(invoiceUuid: string, payload: { available_payment_uuid: string }) {
    return this.http
      .patch<HttpResponse<ChangeCashInvoicePaymentMethodResponse>>(`${environment.api_url_agen}/pembayaran/tunai/change-method/${invoiceUuid}`, JSON.stringify(payload), this.getHeader())
      .pipe(timeout(TIMEOUT_MS));
  }

  searchGlobal(keyword: string) {
    return this.http
      .get(`${environment.api_url_agen}/search?key=${keyword}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOutboundDestinationByBag(bagUuids: string[]) {
    const bagQuery = bagUuids.map((bagUuid) => `bag_uuid[]=${bagUuid}`).join('&');

    return this.http
      .get<HttpResponse<GetOutboundDestination>>(`${environment.api_url_agen}/outbound/destination?${bagQuery}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  sendSuggestion(form: { module: string, description: string }) {
    return this.http
      .post<HttpResponse<any>>(`${environment.api_url_agen}/suggestion`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getReregitrationProfile() {
    return this.http
    .get<HttpResponse<GetRegistrationProfileResponse>>(`${environment.api_url_internal}/validasi-agent`, this.getHeader())
    .pipe(timeout(TIMEOUT_MS))
  }

  // submitReregitrationProfile(form: Required<ReregistrationProfileForm['value']>) {
  submitReregitrationProfile(form: any) {
    return this.http
      .post<HttpResponse<GetRegistrationProfileResponse>>(`${environment.api_url_internal}/validasi-agent/create`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }


  getIndonesiProvince() {
    return this.http
      .get<HttpResponse<GetIndonesiaProvinceResp>>(`${environment.api_url_internal}/validasi-agent/provinces`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getIndonesiCity(provId: number) {
    return this.http
      .get<HttpResponse<GetIndonesiaCityResp>>(`${environment.api_url_internal}/validasi-agent/cities/${provId}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getIndonesiaDistrict(cityId: number) {
    return this.http
      .get<HttpResponse<GetIndonesiaDistrictResp>>(`${environment.api_url_internal}/validasi-agent/districts/${cityId}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getIndonesiaSubdistrict(districtId: number) {
    return this.http
      .get<HttpResponse<GetIndonesiaSubdistrictResp>>(`${environment.api_url_internal}/validasi-agent/subdistricts/${districtId}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getSaldo() {
    return this.http
      .get<GetSaldoResponse>(`${environment.api_url_agen}/finance`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getPendingCashback(query: { page: number }) {
    return this.http
      .get<GetPendingCashbackResponse>(`${environment.api_url_agen}/finance/pending/CASHBACK?page=${query.page}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getPendingRefund(query: { page: number }) {
    return this.http
      .get<GetPendingRefundsResponse>(`${environment.api_url_agen}/finance/pending/REFUND?page=${query.page}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getSaldoMutation(
    type: ObjectValues<typeof GetSaldoMutationSaldoType>,
    transaction: ObjectValues<typeof GetSaldoMutationSaldoTransactionType>,
    query: {
      page: number,
      start_date?: string,
      end_date?: string,
      search?: string,
      modules?: string[],
    }
  ) {
    const queryParams = new URLSearchParams({ page: query.page.toString(), per_page: '10'});
    if (query.search) queryParams.append('search', query.search)
    if (query.start_date)  queryParams.append('start_date', query.start_date);
    if (query.end_date) queryParams.append('end_date', query.end_date)

    let queryModules = '';
    if (query.modules?.length) {
      queryModules = query.modules?.map((module) => `modules[]=${module}`).join('&');
    }

    return this.http
      .get<GetSaldoTransactionsResponse>(`${environment.api_url_agen}/finance/transaction/${type}/${transaction}?${queryParams.toString()}${queryModules ? '&'+queryModules : ''}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printSaldoMutation(query: {
    type: ObjectValues<typeof GetSaldoMutationSaldoType>,
    transaction: ObjectValues<typeof GetSaldoMutationSaldoTransactionType>,
    start_date?: string,
    end_date?: string,
    search?: string,
    modules?: string[],
  }) {
    const queryParams = new URLSearchParams({});
    if (query.search) queryParams.append('search', query.search)
    if (query.start_date)  queryParams.append('start_date', query.start_date);
    if (query.end_date) queryParams.append('end_date', query.end_date)

    let queryModules = '';
    if (query.modules?.length) {
      queryModules = query.modules?.map((module) => `modules[]=${module}`).join('&');
    }

    return this.http
      .get(
        `${environment.api_url_agen}/finance/transaction/${query.type}/${query.transaction}/export?${queryParams.toString()}${queryModules ? '&'+queryModules : ''}`,
        { ...this.getHeader(), responseType: 'blob' }
      )
      .pipe(timeout(TIMEOUT_MS))
  }

  getSaldoMutationInvoice(invoiceUuid: string) {
    return this.http
      .get<any>(`${environment.api_url_agen}/finance/transaction/invoice/${invoiceUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printSaldoMutationInvoice(manifestUuid: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/finance/transaction/invoice/${manifestUuid}/print`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getSaldoMutationPickup(manifestUuid: string) {
    return this.http
      .get<GetSaldoMutationPickupResponse>(`${environment.api_url_agen}/finance/transaction/manifest-pickup/${manifestUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printSaldoMutationPickup(manifestUuid: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/finance/transaction/manifest-pickup/${manifestUuid}/print`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getSaldoMutationCourier(manifestUuid: string) {
    return this.http
      .get<GetSaldoMutationCourierResponse>(`${environment.api_url_agen}/finance/transaction/delivery-claim/${manifestUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  printSaldoMutationCourier(manifestUuid: string) {
    return this.http
      .get<HttpResponse<string>>(`${environment.api_url_agen}/finance/transaction/delivery-claim/${manifestUuid}/print`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getSaldoMutationDate(    type: ObjectValues<typeof GetSaldoMutationSaldoType>) {
    return this.http
      .get<HttpResponse<any>>(`${environment.api_url_agen}/finance/transaction/${type}/date`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  submitClaimSaldo(payload: ClaimSaldoPayload) {
    return this.http
      .post<HttpResponse<ClaimSaldoResponse>>(`${environment.api_url_agen}/finance/refund-request`, JSON.stringify(payload), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getClaimSaldoRequest(requestUuid: string) {
    return this.http
      .get<HttpResponse<ClaimSaldoResponse>>(`${environment.api_url_agen}/finance/refund-request/${requestUuid}`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getBanners() {
    return this.http
      .get<GetBannersResponse>(`${environment.api_url}/banner`)
      .pipe(timeout(TIMEOUT_MS));
  }

  testCreateRetailOrder() {
    return this.http
      .post<HttpResponse<BookingOrderDetailResource>>(`${environment.api_url_agen}/pemesanan/resi-testing`, JSON.stringify({}), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  testCreateRetailOrderMulti() {
    return this.http
      .post<HttpResponse<BookingOrderDetailResource>>(`${environment.api_url_agen}/pemesanan/resi-testing/multi`, JSON.stringify({}), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getRatesCountry(keyword?: string) {
    return this.http
    .get<HttpResponse<GetDestination[]>>(`${environment.api_url}/rates/destination`, {
      headers: this.getHeader().headers,
      params: { ...(keyword && { search: keyword }) },
    })
    .pipe(timeout(TIMEOUT_MS))
  }

  getCommodityUnit() {
    return this.http
      .get<HttpResponse<CommodityUnit[]>>(`${environment.api_url_agen}/unit`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getAgents(form: { lat: number, lng: number }) {
    return this.http
      .post<HttpResponse<GetAgent[]>>(`${environment.api_url_agen}/daftar-agen`, JSON.stringify(form), this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getImportCountries() {
    return this.http
      .get<Country[]>(`${environment.api_url_support}/country`)
      .pipe(timeout(TIMEOUT_MS))
  }

  getImportIndonesiaProvinces() {
    return this.http.get<string[]>(`${environment.api_url_support}/indonesia/provinsi`)
      .pipe(timeout(TIMEOUT_MS));
  }

  getImportCurrencies(countryId: number) {
    return this.http
      .get<string[]>(`${environment.api_url_support}/rate/currency/${countryId}`)
      .pipe(timeout(TIMEOUT_MS))
  }

  getTroubleInbounds() {
    return this.http
    .get<HttpResponse<GetTroubleUser[]>>(`${environment.api_url_agen}/inbound/trouble`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getOutboundDestination() {
    return this.http
      .get<HttpResponse<GetOutboundDestination>>(`${environment.api_url_agen}/outbound/destination`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }

  getExtraRules() {
    return this.http
      .get<GetExtraRuleResponse>(`${environment.api_url}/regulasi/extra-rules`, this.getHeader())
      .pipe(timeout(TIMEOUT_MS))
  }


  private getBaseHeader() {
    return {
      headers: new HttpHeaders({
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
        'Secret'      : 'Bearer 307d9f24ff0bda789b0c0d2e1b8b8c5a',
      })
    };
  }

  private getBypassHeader() {
    return {
      headers: new HttpHeaders({
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
        'authorization'      : 'Bearer 2253aaddac9b678300f2aa3f4aafaa14',
      })
    };
  }

  private getHeader() {
    return {
      headers: new HttpHeaders({
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
        'Secret'      : 'Bearer 307d9f24ff0bda789b0c0d2e1b8b8c5a',
        ...(this.storage.token && { Authorization: 'Bearer ' + this.storage.token })
      })
    };
  }

  private getHeaderStaticToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Accept'      : 'application/json',
        'Content-Type': 'application/json',
        'Secret'      : 'Bearer 307d9f24ff0bda789b0c0d2e1b8b8c5a',
        'Authorization': `Bearer ${token}`
      })
    };
  }

  private getHeaderForConfig() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Consul-Token': 'f05b0791-d273-864e-1e2e-c933e7c8209b',
      })
    }
  }

  private getPDFHeader() {
    return {
      headers: new HttpHeaders({
        'Accept'      : 'application/pdf',
        'Content-Type': 'application/pdf',
        'Secret'      : 'Bearer 307d9f24ff0bda789b0c0d2e1b8b8c5a',
        ...(this.storage.token && { Authorization: 'Bearer ' + this.storage.token })
      }),
    }
  }

  private generateQueryParamsArray(label: string, values: (string | number | never)[]) {
    return values.map((value) => `${label}[]=${value}`).join('&');
  }

}
