import { Option, Map } from "../types/global.types";
import { Commodity, SubCommodityRuleWithCommodity } from "../types/rest.types";
import { COUNTRY_EXT, COUNTRY_CODE } from "./country.const";

export const COUNTRIES_CODE = COUNTRY_CODE;

export const COUNTRIES_EXT = COUNTRY_EXT

export const INDONESIA_ID = 94;

export const SPECIAL_COUNTRIES = {
    ARAB: 2,
    MALAYSIA: 150,
    SINGAPORE: 191,
    TAIWAN: 219,
} as const;


export const Theme = {
    LIGHT: 'light',
    DARK: 'dark',
} as const;

export const INITIAL_THEME = Theme.DARK;


export const COMMODITY_LAINNYA_ID = 1000;
export const SUBCOMMODITY_LAINNYA_ID = null;
export const MASK_SUBCOMMODITY_LAINNYA_ID = 0;
export const COMMODITY_LAINNYA_RAW: Commodity = {
    commodity_description: '',
    commodity_serial_id: COMMODITY_LAINNYA_ID,
    commodity_name: 'Lainnya',
    sub_commodity: [
        {
            sub_commodity_serial_id: SUBCOMMODITY_LAINNYA_ID,
            name: 'Lainnya',
            description: '',
            rule: {
                country_id: 0,
                available: 1,
                charge_description_text: '',
                charge_type: '',
                multi: 1,
                mix: 1,
                premium: 0,
                additional_charge: 0,
                max_qty: undefined,
                max_weight: undefined,
                min_weight_koli: undefined,
            }
        }
    ]
}
export const COMMODITY_LAINNYA: Option<number | null, SubCommodityRuleWithCommodity> = {
    label: 'Lainnya',
    value: MASK_SUBCOMMODITY_LAINNYA_ID,
    keyword: '',
    detail: {
        available: 1,
        charge_description_text: '',
        charge_type: '',
        country_id: 0,
        multi: 1,
        mix: 1,
        premium: 0,
        additional_charge: 0,
        max_qty: undefined,
        max_weight: undefined,
        min_weight_koli: undefined,
        commodity_id: COMMODITY_LAINNYA_ID,
        commodity: 'Lainnya'
    }
};


export const SHIPPING_SERVICE_LABEL: Map = {
    S01: 'regular',
    S02: 'ekonomis',
    S03: 'premium',
    S04: 'special item',
    S05: 'rokok',
} as const;

export const SHIPPING_SERVICE_TEXT_THEME: Map = {
    S01: 'text-danger',
    S02: 'text-success',
    S03: 'text-warning',
    S04: 'text-discovery',
    S05: 'text-info',
} as const;

export const SHIPPING_SERVICE_BADGE_THEME: Map = {
    S01: 'bg-brand-subtler',
    S02: 'bg-success-subtlest',
    S03: 'bg-warning-subtlest',
    S04: 'bg-discovery-subtlest',
    S05: 'bg-info-subtlest',
} as const;


export const PAYMENT_STATUS = {
    PAYMENT_PENDING: 'P01',
    PAYMENT_SUCCESS: 'P02',
    PAYMENT_EXPIRED: 'P03',
    PAYMENT_CANCELLED: 'P04',
    PAYMENT_VOID: 'P05',
} as const;

export const PAYMENT_STATUS_LABEL: Map = {
    [PAYMENT_STATUS.PAYMENT_PENDING]: 'Menunggu Pembayaran',
    [PAYMENT_STATUS.PAYMENT_SUCCESS]: 'Lunas',
    [PAYMENT_STATUS.PAYMENT_EXPIRED]: 'Kadaluarsa',
    [PAYMENT_STATUS.PAYMENT_CANCELLED]: 'Dibatalkan',
    [PAYMENT_STATUS.PAYMENT_VOID]: '-'
} as const;

export const PAYMENT_STATUS_THEME: Map = {
    [PAYMENT_STATUS.PAYMENT_PENDING]: 'bg-warning-subtlest',
    [PAYMENT_STATUS.PAYMENT_SUCCESS]: 'bg-success-subtlest',
    [PAYMENT_STATUS.PAYMENT_EXPIRED]: 'bg-brand-subtler',
    [PAYMENT_STATUS.PAYMENT_CANCELLED]: 'bg-brand-subtler',
    [PAYMENT_STATUS.PAYMENT_VOID]: 'line-defualt'
} as const;


export const ORDER_STATUS = {
    READY: 'siap-proses',
    ON_PROGRESS: 'dalam-proses',
    ON_SHIPMENT: 'dalam-pengiriman',
    TROUBLED: 'bermasalah',
    SENT: 'terkirim',
    CANCELLED: 'dibatalkan',
    WAIT: 'menunggu',
    EXPIRED: 'kadaluarsa',
} as const;

export const ORDER_STATUS_CABANG = {
    ...ORDER_STATUS,
    ON_BASE: 'proses-pusat'
} as const;

export const ORDER_STATUS_LABEL = {
    [ORDER_STATUS_CABANG.READY]: 'Siap Proses',
    [ORDER_STATUS_CABANG.ON_PROGRESS]: 'Dalam Proses',
    [ORDER_STATUS_CABANG.ON_BASE]: 'Proses Pusat',
    [ORDER_STATUS_CABANG.ON_SHIPMENT]: 'Dalam Pengiriman',
    [ORDER_STATUS_CABANG.TROUBLED]: 'Bermasalah',
    [ORDER_STATUS_CABANG.SENT]: 'Terkirim',
    [ORDER_STATUS_CABANG.CANCELLED]: 'Dibatalkan'
} as const;


export const TOAST_TYPE = {
    ERROR: 'bad',
    SUCCESS: 'good',
    INFO: 'alpha-surface',
} as const;


export const OVO_PAYMENT_UUID = '712a0830-e239-4b5e-83f9-636066b31005';
export const OVO_SECOND_INIT = 60;


export const CASH_PAYMENT_UUID = '63b7a455-2e39-4f93-942c-bfe967c415db'
export const CASH_PAYMENT = {
    available_payment_uuid: CASH_PAYMENT_UUID,
    description: '',
    icon: '',
    is_mobile: 0,
    max_amount: 0,
    payment_channel_id: 0,
    payment_method_id: 0,
    status: '',
    payment_channel: {
        payment_channel_alias: 'Tunai',
        payment_channel_code: '',
        payment_channel_name: 'Tunai',
        payment_channel_swift_code: '',
        payment_channel_uuid: '',
    },
    payment_method: {
        payment_method_code: '',
        payment_method_label: 'Cash',
        payment_method_name: 'Cash',
        payment_method_serial_id: 0,
        payment_method_uuid: '',
    }
}


export const ROLE_CABANG_ID = 19;
export const ROLE_AGENT_ID = 16;

export const AGENT_PUSAT_USERNAME = 'AAX0013';


export const MAX_MOBILE_WIDTH = 1024;


export const ChannelOtp = {
    WHATSAPP: 'wa',
    SMS: 'sms',
    EMAIL: 'email',
} as const;


export const OutboundBagStatus = {
    CREATED: 'OB01',
    DELIVERED: 'OB02',
    COMPLETED: 'OB03',
} as const;

export const OutboundBagStatusLabel: Map = {
    [OutboundBagStatus.CREATED]: 'Dibuat',
    [OutboundBagStatus.DELIVERED]: 'Dalam Proses',
    [OutboundBagStatus.COMPLETED]: 'Selesai',
};


export const BookingUserTypeId = {
    RETAIL: '15',
    AGEN: '16',
    CABANG: '19',
} as const;

export const BookingUserTypeIdLabel: Map = {
    [BookingUserTypeId.AGEN]: 'Agen',
    [BookingUserTypeId.RETAIL]: 'Retail',
    [BookingUserTypeId.CABANG]: 'Cabang',
} as const;

export const BookingUserTypeIdTheme: Map = {
    [BookingUserTypeId.AGEN]: 'bg-discovery-subtlest',
    [BookingUserTypeId.CABANG]: 'bg-warning-subtlest',
    [BookingUserTypeId.RETAIL]: 'bg-info-subtlest',
} as const;

export const BookingUserTypeLabel = {
    AGEN: 'Agen',
    RETAIL: 'Retail',
    CABANG: 'Cabang',
} as const;

export const BookingUserTypeLabelTheme: Map = {
    [BookingUserTypeLabel.AGEN]: 'bg-discovery-subtlest',
    [BookingUserTypeLabel.CABANG]: 'bg-warning-subtlest',
    [BookingUserTypeLabel.RETAIL]: 'bg-info-subtlest',
} as const;



export const PaymentCashTab = {
    // IN_PROGRESS: 'berlangsung',
    PENDING: 'menunggu-setoran',
    PAID: 'lunas'
} as const;

export const PaymentCashTabLabel = {
    // [PaymentCashTab.IN_PROGRESS]: 'Berlangsung',
    [PaymentCashTab.PENDING]: 'Menunggu Setoran',
    [PaymentCashTab.PAID]: 'Lunas',
} as const;


export const BagStatus = {
    READY: 'OB01',
    NOT_RECEIVED: 'OB02',
    PARTLY_RECEIVED: 'OB03',
    ALL_RECEIVED: 'OB04',
} as const;

export const BagStatusLabel: Map = {
    [BagStatus.READY]: 'Siap Dikirim',
    [BagStatus.NOT_RECEIVED]: 'Belum Diterima',
    [BagStatus.PARTLY_RECEIVED]: 'Belum Lengkap',
    [BagStatus.ALL_RECEIVED]: 'Lengkap'
} as const;

export const BagStatusTheme: Map = {
    [BagStatus.READY]: '',
    [BagStatus.NOT_RECEIVED]: 'bg-warning-subtlest',
    [BagStatus.PARTLY_RECEIVED]: 'bg-warning-subtlest',
    [BagStatus.ALL_RECEIVED]: 'bg-success-subtlest'
} as const;


export const HandoverMethod = {
    COURIER: 0,
    PICKUP: 1,
} as const;

export const HandoverMethodLabel: Map = {
    [HandoverMethod.COURIER]: 'Kurir Lokal',
    [HandoverMethod.PICKUP]: 'Pick Up',
    '': '',
} as const;

export const HandoverMethodTheme: Map = {
    [HandoverMethod.COURIER]: 'bg-info-subtlest',
    [HandoverMethod.PICKUP]: 'bg-delivery-subtlest',
    '': '',
} as const;

export const HandoverMethodType = {
    COURIER: 'LOCAL_COUR',
    PICKUP: 'PICKUP'
} as const;

export const HandoverMethodTypeLabel: Map = {
    [HandoverMethodType.COURIER]: 'Kurir Lokal',
    [HandoverMethodType.PICKUP]: 'Pick Up'
    
} as const;

export const HandoverMethodTypeTheme: Map = {
    [HandoverMethodType.COURIER]: 'bg-info-subtlest',
    [HandoverMethodType.PICKUP]: 'bg-delivery-subtlest'
} as const;


export const HandoverReceiveType = {
    PICKUP: 'PICKUP',
    DROP: 'DROP',
} as const;

export const HandoverReceiveTypeLabel: Map = {
    [HandoverReceiveType.PICKUP]: 'Dijemput',
    [HandoverReceiveType.DROP]: 'Dikirim',
    '': ''
} as const;

export const HandoverManifestType = {
    PICKUP: 'PICKUP',
    COURIER: 'COURIER',
    KALOG: 'KALOG',
} as const;

export const HandoverManifestTypeLabel: Map = {
    [HandoverManifestType.PICKUP]: 'Pick Up',
    [HandoverManifestType.COURIER]: 'Lokal Kurir',
    [HandoverManifestType.KALOG]: 'Kalog',
    '': '',
} as const;

export const HandoverManifestTypeTextTheme: Map = {
    [HandoverManifestType.PICKUP]: 'text-info',
    [HandoverManifestType.COURIER]: 'text-discovery',
    [HandoverManifestType.KALOG]: 'text-success',
    '': 'text-brand',
} as const;


export const ManifestStatus = {
    PENDING: 'OM00',
    CREATED: 'OM01',
    WAITING_PICKUP: 'OM02',
    UPDATED_COURIER: 'OM03',
    COMPLETED: 'OM04',
} as const;

export const ManifestStatusTheme: Map = {
    [ManifestStatus.CREATED]: 'bg-danger-subtlest',
    [ManifestStatus.WAITING_PICKUP]: 'bg-danger-subtlest',
    [ManifestStatus.UPDATED_COURIER]: 'bg-warning-subtlest',
    [ManifestStatus.COMPLETED]: 'bg-success-subtlest',
} as const;

export const ManifestStatusTextTheme: Map = {
    [ManifestStatus.CREATED]: 'text-brand',
    [ManifestStatus.WAITING_PICKUP]: 'text-warning',
    [ManifestStatus.UPDATED_COURIER]: 'text-info',
    [ManifestStatus.COMPLETED]: 'text-success',
} as const;

export const ManifestStatusLabel: Map = {
    [ManifestStatus.CREATED]: 'Created',
    [ManifestStatus.WAITING_PICKUP]: 'Menunggu Dijemput',
    [ManifestStatus.UPDATED_COURIER]: 'Dalam Perjalanan',
    [ManifestStatus.COMPLETED]: 'Lengkap',
} as const;

export const ManifestStatusIncomingLabel: Map = {
    [ManifestStatus.CREATED]: 'Created',
    [ManifestStatus.WAITING_PICKUP]: 'Requested',
    [ManifestStatus.UPDATED_COURIER]: 'Belum Lengkap',
    [ManifestStatus.COMPLETED]: 'Lengkap',
} as const;

export const ShippingOperator = {
    TIMES: 'F',
    FINAL: '='
} as const;


export const DeliveryMethod = {
    DROP_POINT: 1,
    PICKUP: 2,
} as const;

export const DeliveryDropPointOption = {
    DIRECT: 'directly_agent',
    COURIER: 'local_courier',
} as const;


// export const AgentStatus = {
//     ACTIVE: 'AKTIF',
//     INACTIVE: 'LOCKED',
// } as const;

// export const AgentStatusTheme: Map = {
//     [AgentStatus.ACTIVE]: 'text-success',
//     [AgentStatus.INACTIVE]: 'text-danger',
// } as const;


export const KoliStatus = {
    OUTBOUND_AGENT: 'A02',
    INBOUNDED_CABANG: 'A03',
    DELIVERY_CENTER: 'A04',
    INBOUNDED_CENTER: 'A05',
    CUSTOM_WAREHOUSE: 'A06',
    CHECK_WAREHOUSE: 'A07',
    LABEL_WAREHOUSE: 'A08',
    OUTBOUND_MANIFEST: 'A09',
    LOADING_AIRPORT: 'A10',
    CLEARANCE_ORIGIN: 'A11',
    // OUTBOUND_CABANG: 'A42',
    INBOUNDED_AGENT: 'A17',
    PAYMENT_CONFIRMED: 'A18',
    CANCELLED_USER: 'A20',
    CANCELLED_CENTER: 'A21',
    HOLD_AGENT: 'B32',
    HOLD_CABANG: 'B42',
    CLEARANCE_DESTINATION: 'V01',
    SUCCESS: 'V02',
    TRANSIT_DESTINATION: 'V03',
    TRACKING_DESTINATION: 'V04',
    DELIVERY_DESTINATION: 'V05',
    CARGO_DESTINATION: 'V06',
    TROUBLE_CENTER: 'T01'
}

export const KoliStatusLabel: Map = {
    [KoliStatus.INBOUNDED_CABANG]: "Inbound at Cabang",
    [KoliStatus.INBOUNDED_CENTER]: "Inbound at Headquarter's Warehouse",
    [KoliStatus.INBOUNDED_AGENT]: "Inbound at Agent",
    [KoliStatus.DELIVERY_CENTER]: "Being delivered to the Headquarter's Warehouse",
    [KoliStatus.OUTBOUND_AGENT]: "Outbound from Agent",
    // [KoliStatus.OUTBOUND_CABANG]: "Outbound from Cabang",
    [KoliStatus.INBOUNDED_AGENT]: "Inbound at Agent",
    [KoliStatus.CUSTOM_WAREHOUSE]: "Process Custom at Headquarter's Warehouse",
    [KoliStatus.CHECK_WAREHOUSE]: "On Checking Data Export at Headquarter's Warehouse",
    [KoliStatus.LABEL_WAREHOUSE]: "On Labelling Package at Headquarter's Warehouse",
    [KoliStatus.OUTBOUND_MANIFEST]: "Sorting Destination by Outbound Manifest",
    [KoliStatus.LOADING_AIRPORT]: "On Loading to Airport",
    [KoliStatus.CLEARANCE_ORIGIN]: "Under Custom Clearance at Origin Country",
    [KoliStatus.PAYMENT_CONFIRMED]: "Payment Confirmed",
    [KoliStatus.CANCELLED_USER]: "Cancelled by User",
    [KoliStatus.CANCELLED_CENTER]: "Cancelled by Headquarter",
    [KoliStatus.HOLD_AGENT]: "On Hold Process Inbound at Agent",
    [KoliStatus.HOLD_CABANG]: "On Hold Process Inbound at Branch",
    [KoliStatus.CLEARANCE_DESTINATION]: "Under Custom Clearance at Destination Country",
    [KoliStatus.SUCCESS]: "Delivered or Delivery Success",
    [KoliStatus.TRANSIT_DESTINATION]: "In Transit or at Departed Facility",
    [KoliStatus.TRACKING_DESTINATION]: "Other Tracking Data (LOCATION AGENT or SCHEDULED, etc.)",
    [KoliStatus.DELIVERY_DESTINATION]: "Delivering or Out for Delivery",
    [KoliStatus.CARGO_DESTINATION]: "Cargo in Transit or at HUB",
    [KoliStatus.TROUBLE_CENTER]: "On Hold Process Inbount at Headquarter's Warehouse",
}

export const KoliStatusTextTheme: Map = {
    [KoliStatus.INBOUNDED_CABANG]: "text-info",
    [KoliStatus.INBOUNDED_CENTER]: "text-info",
    [KoliStatus.INBOUNDED_AGENT]: "text-info",
    // [KoliStatus.OUTBOUND_CABANG]: "text-info",
    [KoliStatus.OUTBOUND_AGENT]: "text-info",
    [KoliStatus.CUSTOM_WAREHOUSE]: "text-info",
    [KoliStatus.CHECK_WAREHOUSE]: "text-info",
    [KoliStatus.LABEL_WAREHOUSE]: "text-info",
    [KoliStatus.OUTBOUND_MANIFEST]: "text-info",
    [KoliStatus.LOADING_AIRPORT]: "text-info",
    [KoliStatus.CLEARANCE_ORIGIN]: "text-info",
    [KoliStatus.PAYMENT_CONFIRMED]: "text-info",
    [KoliStatus.CANCELLED_USER]: "text-danger",
    [KoliStatus.CANCELLED_CENTER]: "text-danger",
    [KoliStatus.HOLD_AGENT]: "text-discovery",
    [KoliStatus.HOLD_CABANG]: "text-discovery",
    [KoliStatus.CLEARANCE_DESTINATION]: "text-discovery",
    [KoliStatus.SUCCESS]: "text-discovery",
    [KoliStatus.TRANSIT_DESTINATION]: "text-discovery",
    [KoliStatus.TRACKING_DESTINATION]: "text-discovery",
    [KoliStatus.DELIVERY_DESTINATION]: "text-discovery",
    [KoliStatus.CARGO_DESTINATION]: "text-discovery",
    [KoliStatus.TROUBLE_CENTER]: "text-brand",
}


export const AgentPlaceOwnership = {
    OWNED: 'Milik Keluarga/Pribadi',
    RENT: 'Kontrak/Sewa',
} as const;

export const AgentPlaceName = {
    HOME: 'Rumah',
    SHOPHOUSE: 'Ruko',
} as const;


export const OrderReceiptSize = {
    A4: 'a4',
    BIG_THERMAL: '102',
    SMALL_THERMAL: '58',
} as const;


export const RequestRefundStatus = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    VERIFIED: 'VERIFIED',
} as const;


export const OVERWEIGHT_THRESHOLD = 30;
export const OVERSIZE_THRESHOLD = 100;

export const TipeLayanan = {
    REGULER: 'S01',
    EKONOMIS: 'S02',
    PREMIUM: 'S03',
    SPECIAL_ITEM: 'S04',
} as const;

export const TipePengiriman = {
    DROP: 1,
    PICKUP: 2,
    DROPSHIP: 3,
} as const;

export const TipePengirimanLabel = {
    [TipePengiriman.DROP]: 'Drop',
    [TipePengiriman.PICKUP]: 'Pick Up',
    [TipePengiriman.DROPSHIP]: 'Dropship',
} as const;