import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { GetExportCriteria } from 'src/app/types/rest.types';
import { StateService } from '../state/state.service';
import { OutboundBagDetailForInboundResource, OutboundManifestDetailResource } from 'src/app/types/resource.types';
import { PaymentCashTab } from 'src/app/constants/global.const';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { FirstPasswordRequirement } from 'src/app/types/global.types';
import { GlobalService } from '../global/global.service';
import { SettingAgentTab } from 'src/app/types/route.types';
import { Location } from '@angular/common';

export const NAV_ROUTE = {
  LOGIN: '/login',
  HOME: '/home',
  HOME_DASHBOARD: '/home/dashboard',
  HOME_MENU: '/home/menus',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  FORGOT_PASSWORD_UPDATE: '/forgot-password-update',
  DASHBOARD: '/dashboard',
  BOARDING: '/boarding',
  MOBILE_DASHBOARD: '/home/dashboard',
  CREATE_ORDER: '/create-order',
  CART: '/cart',
  CHECKOUT: '/checkout',
  PAYMENT: '/payments',
  PAYMENT_CASH: '/payment-cash',
  ORDER: '/orders',
  MOBILE_ORDER: '/home/orders',
  INBOUND: '/inbounds',
  INBOUND_TROUBLE_CART: '/inbounds/cart',
  INBOUND_TROUBLE_CHECKOUT: '/inbounds/checkout',
  MANIFEST: '/manifests',
  BAG: '/bags',
  HANDOVER: '/handovers',
  MOBILE_HANDOVER: '/home/handovers',
  BALANCES: '/balances',
  BALANCES_PENDING: '/pending-balance',
  TRACKING: '/tracking',
  REMOTE_AREA: '/remote-area',
  CHECK_RATE: '/check-rate',
  SETTING: '/settings',
  SETTING_MENU: '/settings/menu',
  SETTING_PROFILE: '/settings/profile',
  SETTING_AGENT: '/settings/agent',
  SETTING_ADMIN: '/settings/admin',
  REREGISTRATION: '/re-registration',
  REREGISTRATION_FORM: '/re-registration/form'
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private urls: string[] = [];
  private urlExtras: NavigationExtras[] = [];

  constructor(
    private platform: Platform,
    private state: StateService,
    private navController: NavController,
    private router: Router,
    private global: GlobalService,
    private location: Location,
  ) {
    // this.router.events.subscribe((event) => {
    //   // if (event instanceof NavigationEnd) {
    //   //   this.urls.push(event.urlAfterRedirects);
    //   //   this.urlExtras.push({
    //   //     replaceUrl: true,
    //   //     skipLocationChange: false,
    //   //   })
    //   // }
    // });
  }

  toLoginPage() {
    const url = '/login';
    const urlExtra = { replaceUrl: true, skipLocationChange: false };
    this.changeUrl(url, urlExtra);
  }

  toNewPasswordPage(requirement: FirstPasswordRequirement) {
    this.state.profileForFirstPassword.next(requirement);

    const url = '/new-password';
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false
    };
    this.changeUrl(url, urlExtra);
  }

  toHomePage() {
    const url = NAV_ROUTE.HOME;
    const urlExtra = {
      replaceUrl: true,
      skipLocationChange: false
    };
    this.changeUrl(url, urlExtra);
  }


  toForgotPasswordOtp() {
    const url = NAV_ROUTE.FORGOT_PASSWORD_OTP;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toForgotPasswordUpdate() {
    const url = NAV_ROUTE.FORGOT_PASSWORD_UPDATE;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  /**
   * Native mobile only page
   */
  toBoardingPage() {
    const url = NAV_ROUTE.BOARDING;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: true,
    };
    this.changeUrl(url, urlExtra);
  }


  /**
   * Every size change between mobile and desktop, navigation to the correct size
   * page required and it will return in unended loop when user try to navigat back
   * caused by every location saved on history,
   * isSaveHistory is use to handle that, determine wether history needed to be saved or not
   * @param isSaveHistory
   * @returns
   */
  toDashboardPage(isSaveHistory: boolean = false) {
    if (this.state.isMobileScreen.value) return;
    const url = NAV_ROUTE.DASHBOARD;
    const urlExtra = {
      replaceUrl: true,
      skipLocationChange: isSaveHistory,
    };
    this.changeUrl(url, urlExtra);
  }

  /**
   * Every size change between mobile and desktop, navigation to the correct size
   * page required and it will return in unended loop when user try to navigat back
   * caused by every location saved on history,
   * isSaveHistory is use to handle that, determine wether history needed to be saved or not
   * @param isSaveHistory
   * @returns
   */
  toMobileDashboardPage(isSaveHistory: boolean = false) {
    if (!this.state.isMobileScreen.value) return;
    const url = NAV_ROUTE.MOBILE_DASHBOARD;
    const urlExtra = {
      replaceUrl: true,
      skipLocationChange: isSaveHistory,
    };
    this.changeUrl(url, urlExtra);
  }


  toMobileMenuPage() {
    if (!this.state.isMobileScreen.value) return;
    const url = NAV_ROUTE.HOME_MENU;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  toCreateOrderPage(dropship: boolean = false) {
    const url = NAV_ROUTE.CREATE_ORDER;
    const urlExtra: NavigationExtras = {
      replaceUrl: false,
      skipLocationChange: true,
      ...(dropship && { queryParams: { type: 'dropship' } }),
    };
    this.changeUrl(url, urlExtra);
  }


  toCartPage() {
    const url = NAV_ROUTE.CART;
    const urlExtra = {
      replaceUrl: true,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  toCheckoutPage(state: {
    totalPrice: number,
    transaksiUuids: string[]
  }) {
    const url = NAV_ROUTE.CHECKOUT;
    const urlExtra: NavigationExtras = {
      state,
      replaceUrl: true,
      skipLocationChange: true,
    };
    this.changeUrl(url, urlExtra)
  }


  toPaymentListingPage() {
    const url = NAV_ROUTE.PAYMENT
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toPaymentCountdownPage(invoiceUuid: string) {
    const url = `${NAV_ROUTE.PAYMENT}/${invoiceUuid}/countdown`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toPaymentChangePage() {
    const url = `${NAV_ROUTE.PAYMENT}/change`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toPaymentCashListingPage(status?: typeof PaymentCashTab[keyof typeof PaymentCashTab]) {
    const url = `${NAV_ROUTE.PAYMENT_CASH}`
    const urlExtra: NavigationOptions = {
      replaceUrl: false,
      skipLocationChange: false,
      queryParams: { ...(status && { status }) }
    };
    this.changeUrl(url, urlExtra);
  }

  toPaymentCashDetalPage(invoiceUuid: string) {
    const url = `${NAV_ROUTE.PAYMENT_CASH}/${invoiceUuid}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toPaymentCashCreatePayment(invoiceUuid: string) {
    const url = `${NAV_ROUTE.PAYMENT_CASH}/${invoiceUuid}/create`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: true,
    };
    this.changeUrl(url, urlExtra);
  }
  


  /**
   * Every size change between mobile and desktop, navigation to the correct size
   * page required and it will return in unended loop when user try to navigat back
   * caused by every location saved on history,
   * isSaveHistory is use to handle that, determine wether history needed to be saved or not
   * @param isSaveHistory
   * @returns
   */
  toOrderListingPage(isSaveHistory: boolean = false) {
    if (this.state.isMobileScreen.value) {
      this.toMobileOrderListingPage();
      return;
    };
    const url = NAV_ROUTE.ORDER;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: isSaveHistory,
    };
    this.changeUrl(url, urlExtra);
  }

  /**
   * Every size change between mobile and desktop, navigation to the correct size
   * page required and it will return in unended loop when user try to navigat back
   * caused by every location saved on history,
   * isSaveHistory is use to handle that, determine wether history needed to be saved or not
   * @param isSaveHistory
   * @returns
   */
  toMobileOrderListingPage(isSaveHistory: boolean = false) {
    if (!this.state.isMobileScreen.value) {
      this.toOrderListingPage();
      return;
    };
    const url = NAV_ROUTE.MOBILE_ORDER;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: isSaveHistory,
    };
    this.changeUrl(url, urlExtra);
  }


  toOrderDetailPage(orderUuid: string) {
    const url = `${NAV_ROUTE.ORDER}/${orderUuid}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false
    };
    this.changeUrl(url, urlExtra);
  }


  toHandoverListingPage() {
    if (this.global.appIsMobile()) {
      this.toMobileHandoverListingPage();
      return;
    }
    this.toWebHandoverWebListingPage();
  }

  /**
   * Every size change between mobile and desktop, navigation to the correct size
   * page required and it will return in unended loop when user try to navigat back
   * caused by every location saved on history,
   * isSaveHistory is use to handle that, determine wether history needed to be saved or not
   * @param isSaveHistory
   * @returns
   */
  toWebHandoverWebListingPage(isSaveHistory: boolean = false) {
    const url = `${NAV_ROUTE.HANDOVER}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: isSaveHistory
    };
    this.changeUrl(url, urlExtra);
  }

  /**
   * Every size change between mobile and desktop, navigation to the correct size
   * page required and it will return in unended loop when user try to navigat back
   * caused by every location saved on history,
   * isSaveHistory is use to handle that, determine wether history needed to be saved or not
   * @param isSaveHistory
   * @returns
   */
  toMobileHandoverListingPage(isSaveHistory: boolean = false) {
    const url = `${NAV_ROUTE.MOBILE_HANDOVER}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: isSaveHistory
    };
    this.changeUrl(url, urlExtra);
  }


  toHandoverCourier(manifestUuid: string, manifest: OutboundManifestDetailResource) {
    this.state.handoverManifestToUpdateCourier.next(manifest);

    const url = `${NAV_ROUTE.HANDOVER}/${manifestUuid}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toHandoverProgressPage(manifestUuid: string) {
    const url = `${NAV_ROUTE.HANDOVER}/progress/${manifestUuid}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  toInboundListingPage() {
    const url = NAV_ROUTE.INBOUND;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toInboundValidatePage(awbNumber: string) {
    const url = `${NAV_ROUTE.INBOUND}/${awbNumber}/validate`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: true,
    };
    this.changeUrl(url, urlExtra);``
  }

  toInboundTroubleCart() {
    const url = NAV_ROUTE.INBOUND_TROUBLE_CART;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toInboundTroubleCheckout() {
    const url = NAV_ROUTE.INBOUND_TROUBLE_CHECKOUT;
    const urlExtra = {
      replaceUrl: true,
      skipLocationChange: true,
    };
    this.changeUrl(url, urlExtra);
  }


  toManifestListingPage() {
    const url = NAV_ROUTE.MANIFEST;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toManifestDetailPage(manifestUuid: string) {
    const url = `${NAV_ROUTE.MANIFEST}/${manifestUuid}`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  toBagEditPage(bagUuid: string) {
    const url = `${NAV_ROUTE.BAG}/${bagUuid}/edit`;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    }
    this.changeUrl(url, urlExtra);
  }


  toBalanceListingPage() {
    const url = NAV_ROUTE.BALANCES;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toBalancePendingListingPage() {
    const url = NAV_ROUTE.BALANCES_PENDING;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  toTrackingPage(awb?: string) {
    const url = NAV_ROUTE.TRACKING;
    const urlExtra: NavigationExtras = {
      replaceUrl: false,
      skipLocationChange: false,
      ...(awb && { queryParams: { awb }})
    };
    this.changeUrl(url, urlExtra);
  }

  toRemoteAreaPage() {
    const url = NAV_ROUTE.REMOTE_AREA;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toCheckRatePage(basicCriteria?: GetExportCriteria) {
    const url = NAV_ROUTE.CHECK_RATE;
    const urlExtra = {
      state: basicCriteria,
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }


  toSettingMenuPage() {
    const url = NAV_ROUTE.SETTING_MENU;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toSettingProfilePage() {
    const url = NAV_ROUTE.SETTING_PROFILE;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toSettingAgentPage(tab: SettingAgentTab) {
    const url = `${NAV_ROUTE.SETTING_AGENT}/${tab}`;
    const urlExtra = {
      replaceUrl: true,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toSettingAdminPage() {
    const url = NAV_ROUTE.SETTING_ADMIN;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toReregistration() {
    const url = NAV_ROUTE.REREGISTRATION;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
    };
    this.changeUrl(url, urlExtra);
  }

  toReregistrationForm(step: 0 | 1 | 2 | 3 = 0) {
    const url = NAV_ROUTE.REREGISTRATION_FORM;
    const urlExtra = {
      replaceUrl: false,
      skipLocationChange: false,
      queryParams: { step }
    };
    this.changeUrl(url, urlExtra);
  }


  pop() {
    if (this.platform.is('hybrid')) {
      return new Promise((resolve, reject) => {
        try {
          if (this.urls.length && this.urlExtras.length) {
            let backDestinationUrl       = this.urls[this.urls.length - 2];
            let backDestinationUrlExtras = this.urlExtras[this.urlExtras.length - 2];

            while(this.urls.length && backDestinationUrlExtras.skipLocationChange) {
              backDestinationUrl = this.urls[this.urls.length - 2];
              backDestinationUrlExtras = this.urlExtras[this.urlExtras.length - 2];

              this.urls.pop();
              this.urlExtras.pop();

              if (!backDestinationUrlExtras.skipLocationChange) {
                this.saveUrl(backDestinationUrl, backDestinationUrlExtras)
              }
            }

            const modifiedPrevUrlExtras: NavigationExtras = {
              ...backDestinationUrlExtras,
              queryParams: {
                ...backDestinationUrlExtras.queryParams,
                refresh: true,
              }
            }

            this.navController.navigateBack([backDestinationUrl], modifiedPrevUrlExtras).then(() => {
              this.urls.pop();
              this.urlExtras.pop();
              resolve(true);
            }).catch(_ => {
              reject()
            });

            return;
          };
          this.toHomePage();
        } catch (_) {
          this.toHomePage();
        }
      })
    }
  
    return new Promise((resolve, reject) => {
      this.location.historyGo(-1);
      resolve(true);
    })
  }


  private saveUrl(url: string, urlExtra: NavigationExtras) {
    this.urls.push(url);
    this.urlExtras.push(urlExtra);
  }

  private changeUrl(url: string, urlExtra: NavigationExtras) {
    this.saveUrl(url, urlExtra);

    if (urlExtra.replaceUrl) {
      this.navController.navigateRoot([url], urlExtra)
      return;
    }

    this.navController.navigateForward([url], urlExtra)
  }
}
