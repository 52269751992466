import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-template-preloading',
  templateUrl: './template-preloading.component.html',
  styleUrls: ['./template-preloading.component.scss'],
  standalone: true,
  imports: [LottieComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TemplatePreloadingComponent  implements OnInit {

  options: AnimationOptions = {
    path: '/assets/preloader.json',
  }

  constructor() { }

  ngOnInit() {}

}
