import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global/global.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'app-template-lock',
  templateUrl: './template-lock.component.html',
  styleUrls: ['./template-lock.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class TemplateLockComponent  implements OnInit {

  constructor(
    public global: GlobalService,
    private navigation: NavigationService,
  ) { }

  ngOnInit() {}

  toPaymentCashPage() {
    this.global.hideLockDialog();
    this.navigation.toPaymentCashListingPage();
  }

}
