export const InvoiceTroubleStatus = {
  PENDING: 'belum-lunas',
  PAID: 'lunas',
} as const;

export const GetManifestStatus = {
  PENDING: 'pending',
  INCOMING: 'incoming',
  ON_GOING: 'on-going',
  COMPLETED: 'completed',
} as const;

export const GetInvoicesStatus = {
  PENDING: 'menunggu-pembayaran',
  PAID: 'dibayar',
  CANCELLED: 'dibatalkan',
} as const;

export const GetOrdersStatus = {
  READY: 'siap-proses',
  ON_PROGRESS: 'dalam-proses',
  ON_SHIPMENT: 'dalam-pengiriman',
  TROUBLED: 'bermasalah',
  SENT: 'terkirim',
  CANCELLED: 'dibatalkan',
  WAIT: 'menunggu',
  EXPIRED: 'kadaluarsa',
} as const;

export const GetSaldoMutationSaldoType = {
  CASHBACK: 'CASHBACK',
  REFUND: 'REFUND',
} as const;

export const GetSaldoMutationSaldoTransactionType = {
  ALL: 'semua',
  INCOME: 'pemasukan',
  EXPENSE: 'pengeluaran',
  HOLD: 'tertahan',
} as const;
