import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Subject } from 'rxjs';
import { INITIAL_THEME } from 'src/app/constants/global.const';
import { ObjectValues } from 'src/app/types/global.types';
import { Profile } from 'src/app/types/rest.types';

const STORAGE_KEY = {
  IS_PASSED_BOARDING: 'is_passed_boarding',
  TOKEN: 'token',
  PROFILE: 'profile',
  ROLE: 'role',
  // CREATE_MANIFEST_UNDERSTAND: 'create_manifest_understand',
  THEME: 'theme',
  SEARCH: 'search'
} as const


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  [STORAGE_KEY.IS_PASSED_BOARDING]?: string;
  [STORAGE_KEY.TOKEN]?: string;
  [STORAGE_KEY.PROFILE]?: Profile;
  [STORAGE_KEY.ROLE]?: number;
  [STORAGE_KEY.THEME]?: 'dark' | 'light';
  // [STORAGE_KEY.CREATE_MANIFEST_UNDERSTAND]?: string;
  [STORAGE_KEY.SEARCH]?: string[];

  profile$ = new Subject<Profile>();

  constructor() { }

  async loadInitialStorages() {
    const storagesToLoad: Promise<any>[] = [
      this.getIsPassedBoarding(),
      this.getProfile(),
      this.getToken(),
      this.getRole(),
      this.getTheme(),
      this.getSearch(),
      // this.getCreateManifestUnderstand(),
    ];

    return Promise.all(storagesToLoad);
  }

  async setSession(profile: Profile, token: string) {
    const sessionToSave = [
      this.setProfile(profile),
      this.setToken(token),
      this.setRole(profile.role_type)
    ];

    return Promise.all(sessionToSave);
  }

  async removeSession() {
    const sessionToRemove = [
      this.removeStorage(STORAGE_KEY.PROFILE),
      this.removeStorage(STORAGE_KEY.TOKEN),
      this.removeStorage(STORAGE_KEY.ROLE),
    ];

    return Promise.all(sessionToRemove)
  }


  async setIsPassedBoarding() {
    await Preferences.set({
      key: STORAGE_KEY.IS_PASSED_BOARDING,
      value: 'true',
    });
    this.is_passed_boarding = 'true';
  }

  async getIsPassedBoarding() {
    const { value } = await Preferences.get({ key: STORAGE_KEY.IS_PASSED_BOARDING });
    this.is_passed_boarding = value ? JSON.parse(value) : undefined;
  }


  async setProfile(profile: any) {
    await Preferences.set({
      key  : STORAGE_KEY.PROFILE,
      value: JSON.stringify(profile),
    });
    this.profile = profile;
    this.profile$.next(profile);
  }

  async getProfile() {
    const { value } = await Preferences.get({ key: STORAGE_KEY.PROFILE });
    this.profile = value ? JSON.parse(value) : null;
    this.profile$.next(value ? JSON.parse(value) : null);
    this.role = this.profile?.role_type
  }


  async setRole(role: number) {
    await Preferences.set({
      key  : STORAGE_KEY.ROLE,
      value: JSON.stringify(role),
    });
    this.role = role;
  }

  async getRole() {
    const { value } = await Preferences.get({ key: STORAGE_KEY.ROLE });
    this.role = value ? JSON.parse(value) : null;
  }


  async setToken(token: string) {
    await Preferences.set({
      key: STORAGE_KEY.TOKEN,
      value: token,
    });
    this.token = token;
  }

  async getToken() {
    const { value } = await Preferences.get({ key: STORAGE_KEY.TOKEN });
    this.token = value ?? undefined;
  }


  // async setCreateManifestUnderstand() {
  //   await Preferences.set({
  //     key: STORAGE_KEY.CREATE_MANIFEST_UNDERSTAND,
  //     value: 'true',
  //   });
  //   this.create_manifest_understand = 'true';
  // }

  // async getCreateManifestUnderstand() {
  //   const { value } = await Preferences.get({ key: STORAGE_KEY.CREATE_MANIFEST_UNDERSTAND });
  //   this.create_manifest_understand = value ?? undefined;
  // }


  async setTheme(token: 'light'|'dark') {
    await Preferences.set({
      key: STORAGE_KEY.THEME,
      value: token,
    });
    this.theme = token;
  }

  async getTheme() {
    const { value } = await Preferences.get({ key: STORAGE_KEY.THEME });
    this.theme = (value as 'light' | 'dark') ?? INITIAL_THEME;
  }


  async setSearch(search: string) {
    if ((this.search ?? []).indexOf(search) === -1) {
      const newSearch = [...(this.search ?? []), search];
      await Preferences.set({
        key: STORAGE_KEY.THEME,
        value: JSON.stringify(newSearch),
      });

      this.search = newSearch;
    }
  }

  async getSearch() {
    const { value } = await Preferences.get({ key: STORAGE_KEY.SEARCH });
    this.search = JSON.parse(value || '[]') as string[];
  }



  async removeStorage(key: typeof STORAGE_KEY[keyof typeof STORAGE_KEY]) {
    await Preferences.remove({ key });
    this[key] = undefined;
  }
}

export async function SetStorage(key: string, value: any): Promise<void> {
  await Preferences.set({
    key: key,
    value: JSON.stringify(value),
  });
}

export async function GetStorage<T = any>(key: string): Promise<T> {
  const item = await Preferences.get({ key: key });

  return JSON.parse(item.value!);
}

export async function RemoveStorage(key: string): Promise<void> {
  await Preferences.remove({
    key: key,
  });
}
