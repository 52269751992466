import { Injectable,  } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { INDONESIA_ID, MAX_MOBILE_WIDTH, SPECIAL_COUNTRIES } from 'src/app/constants/global.const';
import { CountryFlag, OptionDestination, OptionOrigin, Option, FirstPasswordRequirement } from 'src/app/types/global.types';
import { ExtraRulesOver } from 'src/app/types/model.types';
import { OutboundBagDetailForInboundResource, OutboundManifestDetailResource, TransaksiRawResource } from 'src/app/types/resource.types';
import { PaymentMethodDefault, PaymentMethodRecomended, GetInboundTroubleInvoice, GetInvoice, GetTroubleUser, GetOutboundDestination, GetProfile, GetProfileStatsResponse, GetRegistrationProfileResponse, Profile } from 'src/app/types/rest.types';
import { RestService } from '../rest/rest.service';
import { StorageService } from '../storage/storage.service';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  profile: BehaviorSubject<Profile | undefined> = new BehaviorSubject<Profile | undefined>(undefined);
  profileBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  profileForFirstPassword: BehaviorSubject<FirstPasswordRequirement | undefined> = new BehaviorSubject<FirstPasswordRequirement | undefined>(undefined);

  saldo: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  handoverDestination: BehaviorSubject<GetOutboundDestination | undefined> = new BehaviorSubject<GetOutboundDestination | undefined>(undefined);

  position: { lat: number, lng: number } = { lat: 0, lng: 0 };

  commodityUnits: BehaviorSubject<Option<number>[]> = new BehaviorSubject<Option<number>[]>([]);

  origins: BehaviorSubject<OptionOrigin<number>[]> = new BehaviorSubject<OptionOrigin[]>([])
  countryDestinations: BehaviorSubject<OptionDestination[]> = new BehaviorSubject<OptionDestination[]>([]);

  importCountries: BehaviorSubject<OptionDestination[]> = new BehaviorSubject<OptionDestination[]>([]);
  importProvinces: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  importCurrencies: BehaviorSubject<Record<number, string[]>> = new BehaviorSubject<Record<number, string[]>>({});

  carts: BehaviorSubject<TransaksiRawResource[]> = new BehaviorSubject<TransaksiRawResource[]>([]);
  cartCountries: BehaviorSubject<CountryFlag[]> = new BehaviorSubject<CountryFlag[]>([]);

  allPayments: BehaviorSubject<PaymentMethodDefault[]> = new BehaviorSubject<PaymentMethodDefault[]>([]);
  cashPayment: BehaviorSubject<PaymentMethodRecomended|undefined> = new BehaviorSubject<PaymentMethodRecomended|undefined>(undefined);
  recommendPayments: BehaviorSubject<PaymentMethodRecomended[]> = new BehaviorSubject<PaymentMethodRecomended[]>([]);

  
  pendingPayments: BehaviorSubject<GetInvoice[]> = new BehaviorSubject<GetInvoice[]>([]);

  troubleInbounds: BehaviorSubject<GetTroubleUser[]> = new BehaviorSubject<GetTroubleUser[]>([]);

  invoiceToChange: string = '';

  invoiceTroubleToChange?: GetInboundTroubleInvoice;


  handoverManifestToUpdateCourier: BehaviorSubject<OutboundManifestDetailResource | undefined> = new BehaviorSubject<OutboundManifestDetailResource | undefined>(undefined);

  isMobileScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth <= MAX_MOBILE_WIDTH);

  isScanActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCameraActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  extraRules: BehaviorSubject<ExtraRulesOver> = new BehaviorSubject<ExtraRulesOver>({
    overweight: [],
    oversize: [],
  });

  isKeyboardActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private forgotPassword: {
    ext: string,
    username: string,
    token: string,
  } = {
    ext: '',
    username: '',
    token: '',
  };

  constructor(
    private api: RestService,
    private global: GlobalService,
    private storage: StorageService,
  ) {}

  async populatePendingPayments() {
    try {
      const response = await lastValueFrom(this.api.getInvoices('menunggu-pembayaran', { page: 1, per_page: 100 }));
      this.pendingPayments.next(response.data);
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data pembayaran yang tertunda');
    }
  }

  async populateProfile() {
    if (!this.storage.token) return

    try {
      this.profileBusy.next(true);

      const response = await lastValueFrom(this.api.getProfile());

      this.saldo.next(response.data.saldo_total);
      this.profile.next(response.data);

      this.storage.setProfile(response.data);
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data profil');
    } finally {
      this.profileBusy.next(false);
    }
  }

  async populateCountryDestination() {
    try {
      const response = await lastValueFrom(this.api.getRatesCountry());
  
      const specialCountryIds = Object.values(SPECIAL_COUNTRIES);
      specialCountryIds.forEach((specialCountryId) => {
        const specialCountryIndex = response.data.findIndex((country) => country.value === specialCountryId);
        const specialCountry = response.data[specialCountryIndex];
  
        if (specialCountry) {
          response.data = response.data.filter(country => country.value !== specialCountryId);
          response.data.unshift(specialCountry);
        }
      })
  
      response.data = response.data.filter((country) => country.value !== INDONESIA_ID);
  
      this.countryDestinations.next(response.data.map((country) => ({
        label  : country.label,
        value  : country.value,
        detail : { country_code: country.code },
        keyword: country.code + country.label + country.alias,
      })));
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data negara tujuan');
    }
  }

  async populateCommodityUnit() {
    try {
      const response = await lastValueFrom(this.api.getCommodityUnit());
  
      this.commodityUnits.next(response.data!.map((unit) => ({
        label  : unit.unit_name,
        value  : unit.unit_serial_id,
        keyword: unit.unit_name,
        detail: {}
      })));
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data satuan komoditas');
    }
  }

  async populateCartItems() {
    try {
      const response = await lastValueFrom(this.api.getCartItems());
  
      this.carts.next(response.data!);
      this.cartCountries.next(response.data!.reduce((current: CountryFlag[], item) => {
        if (current.filter((country) => country.name === item.alamat_penerima.country.country_name).length) {
          return current;
        }
  
        current.push({
          name: item.alamat_penerima.country.country_name,
          code: item.alamat_penerima.country.country_code,
        })
        return current;
      }, []));
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data keranjang');
    }
  }

  async populateAvailablePayments() {
    try {
      const response = await lastValueFrom(this.api.getAvailablePayments());
  
      this.allPayments.next(response.data!.payment_method);
      this.recommendPayments.next(response.data!.recommended);
      this.cashPayment.next(response.data.cash)
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data metode pembayaran');
    }
  }

  async populateAgents(form: { lat: number, lng: number }) {
    try {
      const response = await lastValueFrom(this.api.getAgents(form));
  
      this.origins.next(response.data.map((agent) => ({
        label: agent.fullname,
        value: agent.users_id,
        detail: {
          area: agent.address.address_city,
          province: agent.address.address_province,
        },
        keyword: agent.fullname + ' ' + agent.address.address_city + ' ' + agent.address.address_province,
      })));
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data agen');
    }
  }

  async populateImportCountries() {
    try {
      const response = await lastValueFrom(this.api.getImportCountries());
      this.importCountries.next(response.map((country) => ({
        label: country.country_name,
        value: country.country_serial_id,
        keyword: country.country_name + ' ' + country.country_alias + ' ' + country.country_code,
        detail: { country_code: country.country_code }
      })))
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data negara asal');
    }
  }

  async populateImportIndonesiaProvinces() {
    try {
      const response = await lastValueFrom(this.api.getImportIndonesiaProvinces());
      this.importProvinces.next(response.map((province) => ({
        value: province,
        label: province,
        keyword: province,
        detail: {}
      })))
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data provinsi asal');
    }
  }

  async populateImportCurrency(countryId: number) {
    try {
      const response = await lastValueFrom(this.api.getImportCurrencies(countryId));
      this.importCurrencies.next({
        ...this.importCurrencies,
        [countryId]: response,
      })
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data mata uang asal');
    }
  }

  async populateTroubleInbounds() {
    try {
      const response = await lastValueFrom(this.api.getTroubleInbounds());
      this.troubleInbounds.next(response.data);   
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data inbound bermasalah');
    }
  }

  async populateHandoverDestination() {
    try {
      const response = await lastValueFrom(this.api.getOutboundDestination());
      this.handoverDestination.next(response.data);
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data tujuan handover');
    }
  }
  
  async populateExtraRules() {
    try {
      const response = await lastValueFrom(this.api.getExtraRules());
      this.extraRules.next(response.data);
    } catch (error) {
      this.global.errorHandler(error, 'Gagal memuat data aturan tambahan');
    }
  }


  mutateForgotPassword(form: { username: string, ext: string, token: string }) {
    this.forgotPassword = form;
  }

  getForgotPassword() {
    return this.forgotPassword;
  }


  resetStateOnLogout() {
    this.profile.next(undefined);
  }
}
