import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { LottieModule } from 'ngx-lottie';

import player from 'lottie-web';
import { Capacitor } from '@capacitor/core';
import { IconComponent } from './components/icon/icon.component';
import { DiscordService } from './services/discord/discord.service';
const playerFactory = () => player;

import localeId from '@angular/common/locales/id'; 
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeId, 'id'); 


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: Capacitor.getPlatform() === 'web' ? false : true }),
    AppRoutingModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    IconComponent,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: ErrorHandler,
      useClass: DiscordService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'id'
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
