import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Profile } from 'src/app/types/rest.types';

@Component({
  selector: 'app-modal-confirm-logout',
  templateUrl: './modal-confirm-logout.component.html',
  styleUrls: ['./modal-confirm-logout.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class ModalConfirmLogoutComponent  implements OnInit {

  modal?: HTMLIonModalElement;

  profile?: Profile;

  constructor(
    private auth: AuthService,
    public storage: StorageService,
  ) { }

  ngOnInit() {
  }
  
  logout() {
    this.auth.isAuthenticated.next(false);
    this.modal?.dismiss();
  }
}
