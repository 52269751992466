import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { format } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { Device } from '@capacitor/device';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DiscordService implements ErrorHandler {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private location: Location
  ) {}

  async handleError(error: any) {
    try {
      if (error.message.toLowerCase().includes('httperrorresponse')) {
        return;
      }

      const { message, name, status, statusText, url, stack } = error;

      const fields = [];

      let appVersion = 'WEB:' + environment.version;
      if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('AppUpdate')) {
        await AppUpdate.getAppUpdateInfo()
          .then((info) => (appVersion = info.availableVersionCode || info.currentVersionCode))
          .catch((_) => (appVersion = 'APP DEV: ' + environment.version));
      }

      fields.push({
        name: 'App Version',
        value: appVersion,
        inline: false,
      });

      fields.push({
        name: 'Path',
        value: this.location.path(),
        inline: false,
      });

      if (name) {
        fields.push({
          name: 'Name',
          value: name,
          inline: false,
        });
      }

      if (message) {
        fields.push({
          name: 'Message',
          value: message,
          inline: false,
        });
      }

      if (status) {
        fields.push({
          name: 'Status',
          value: status,
          inline: false,
        });
      }

      if (statusText) {
        fields.push({
          name: 'Status Text',
          value: statusText,
          inline: false,
        });
      }

      if (url) {
        fields.push({
          name: 'URL',
          value: url,
          inline: false,
        });
      }

      if (message.length < 200 && stack) {
        fields.push({
          name: 'Stack',
          value: stack,
          inline: false,
        });
      }

      if (this.storage.profile) {
        fields.push({
          name: 'User',
          value: `${this.storage.profile.username}[${this.storage.profile.fullname}]`,
          inline: false,
        });
      }

      if (!Capacitor.isNativePlatform()) {
        fields.push({
          name: 'Browser',
          value: navigator.userAgent,
          inline: false,
        });
      }

      if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Device')) {
        let device = await Device.getInfo();
        if (!device) throw new Error('Device not found');

        let platform = `${device.manufacturer} ${device.model} ${device.platform} ${device.operatingSystem}`;
        fields.push({
          name: 'Device',
          value: platform,
          inline: false,
        });
      }

      let urlDiscord =
        'https://discordapp.com/api/webhooks/1237329958083498024/J0wLrZSB54RVbcFuJKyGQk7DJypA6xSQnbbyqWMoouWzs35FLs_2EQJfqAR94YQwPUf3';
      if (environment.production)
        urlDiscord =
          'https://discordapp.com/api/webhooks/1237330148458758155/-Ruiz7HMj9uay1hN2uN6KdhVqclogSoSn6wPRJTQUhw4FpIXwGbnIKritOt7x1wYTJa2';

      await lastValueFrom(
        this.http.post(
          urlDiscord,
          JSON.stringify({
            username: 'ERROR FE AGEN CABANG',
            avatar_url: 'https://abangexpress.id/img/tm.jpeg',
            content: '@everyone hi',
            embeds: [
              {
                title: 'ADA ERROR',
                description: 'TOLONG SEGERA CHECK',
                type: 'rich',
                url: '',
                timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                thumbnail: {
                  url: 'https://abangexpress.id/img/tm.jpeg',
                },
                footer: {
                  text: 'Copyright © 2022 | Abang Express',
                  icon_url: 'https://abangexpress.id/img/tm.jpeg',
                },
                fields,
              },
            ],
          }),
          {
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        )
      );
    } catch (error) {
      console.error(error);
    }
  }
}
